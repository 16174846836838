import React, { useState, useEffect } from "react";
import axios from "axios";
import MDBox from "../../components/MDBox";
import "react-toastify/dist/ReactToastify.css";
import DataTable1 from "../../CUSTOM_COMPONENTS/CUSTOM_TABLES/table1/data_table";

function GeneratedDomainsTAble() {
  const [tableData, setTableData] = useState({
    columns: [
      {
        Header: "created_at",
        accessor: "created_at_gmt3",
        width: "10px",
      },
      {
        Header: "created_by",
        accessor: "created_by",
        width: "10px",
      },
      {
        Header: "domain",
        accessor: "domain",
        width: "15px",
      },
      {
        Header: "category",
        accessor: "category",
        width: "10px",
      },
      {
        Header: <span style={{ paddingRight: "65px" }}>keywords</span>,
        accessor: "keywords",
        width: "10px",
      },
      {
        Header: "geo",
        accessor: "geo",
        width: "5px",
      },
      {
        Header: "da_campaign_id",
        accessor: "da_campaign_id",
        width: "5px",
      },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://dashboard-1049948018753.us-central1.run.app/api/generated_domains_table/"
        );
        const rows = response.data.map((row) => ({
          created_by: row.created_by,
          created_at_gmt3: row.created_at_gmt3,
          domain: row.domain.split("/direct/")[0],
          keywords: row.keywords,
          category: row.category,
          geo: row.geo,
          da_campaign_id: row.da_campaign_id,
        }));
        setTableData((prevState) => ({ ...prevState, rows: rows }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filteredData = tableData.rows.filter((row) =>
    Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
  );

  return (
    <DataTable1
      table={{
        ...tableData,
        rows: filteredData,
      }}
      canSearch
      searchValue={search}
      entriesPerPage={false}
      onSearchChange={setSearch}
    />
  );
}

export default GeneratedDomainsTAble;
