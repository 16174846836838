import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "CUSTOM_COMPONENTS/custom_dropdown";
import OptionsCheckboxList from "CUSTOM_COMPONENTS/options_checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CustomNumericInputField from "CUSTOM_COMPONENTS/custom_numeric_input_field";
import CustomInputFieldMultiline from "CUSTOM_COMPONENTS/custom_input_field_multiline";
import Button from "@mui/material/Button";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";
import DialogFormGenerateDaDomain from "../../GENERATE_LINK/DA_generate_domain_dialog";
import { fetchCategories } from "../../GENERATE_LINK/constants";

function CopyCampaign() {
  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";
  const API_URL_2 = "https://dashboard-1049948018753.us-central1.run.app";
  const API_URL_UPDATE = "https://fb-campaign-creator-only-campaigns-tmgx45bhsa-uc.a.run.app";
  const API_URL_GET_DATA = "https://fb-campaign-creator-get-data-1049948018753.us-central1.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [CampaignNamesAll, setCampaignNamesAll] = useState([]);

  const [selectedPage, setSelectedPage] = useState("");
  const [pagesAll, setPagesAll] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);
  const [inputBudget, setInputBudget] = useState("5");
  const [inputLinkForFB, setInputLinkForFB] = useState("");

  const [inputSearchTerm, setInputSearchTerm] = useState("");
  const [inputCt, setInputCt] = useState("184");
  const [categoriesAll, setCategoriesAll] = useState([]);

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const Tips = [
    "This form is for cloning campaigns. Select a parent campaign, account, FB pages, FB link, and budget.",
    "Other data will be inherited from the parent campaign.",
    "All ad sets and ads with the same status as the parent will be cloned.",
    "The campaign will be activated the next day if it's after 8 AM (account time), or immediately if it's before 8 AM.",
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL}/get_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);

        const pageResponse = await fetch(`${API_URL}/get_pages_all/`);
        const pagesData = await pageResponse.json();
        setPagesAll(pagesData);

        const categoriesResponse = await fetchCategories();
        setCategoriesAll(categoriesResponse);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCampaignDataG = async () => {
      if (!selectedCampaignName) return;

      // Making the API request
      try {
        const response = await fetch(`${API_URL_GET_DATA}/campaign_get_data/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_email: userEmail, // Assume userEmail is defined somewhere
            properties: {
              campaign_id: selectedCampaignName,
            },
          }),
        });

        const data = await response.json();

        setSelectedAccount(data.account_id);
        setSelectedPage(data.page_id);
        setInputLinkForFB(data.fb_link_for_fb);
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };

    fetchCampaignDataG();
  }, [selectedCampaignName]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/get_campaigns_names_adsets_types/`);
        const data = await response.json();

        const emailsNames = {
          "Artemyusi@gmail.com": "Artem",
          "Vlad0986613794@gmail.com": "Vlad",
          "nuzhnyias@gmail.com": "Alex",
          "xxxuko@gmail.com": "Anya",
          "Hembarskyi@gmail.com": "Michail",
          "Hembarskyi_user@gmail.com": "Michail",
          "andrew@salesbrush.com": "Andrew",
          "andreylipovskiy0@gmail.com": "Andrey",
          "mcerneva2@gmail.com": "Maryna",
        };

        let filteredCampaignNames;

        if (userRole === "admin") {
          // Extract unique values from the data for admin role
          filteredCampaignNames = [...new Set(data.map((item) => item.campaign_id_name))];
        } else {
          const userName = emailsNames[userEmail];
          // Filter campaign names based on the user's name
          filteredCampaignNames = [
            ...new Set(
              data
                .map((item) => item.campaign_id_name)
                .filter((campaignName) => campaignName.includes(userName))
            ),
          ];
        }

        // Update state with filtered values
        setCampaignNamesAll(filteredCampaignNames);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCopyCampaign = () => {
    if (!selectedCampaignName || !selectedAccount || !selectedPage || !inputBudget) {
      toast.error("Please fill all required fields");
      return;
    }
    setIsLoading(true);
    fetch(`${API_URL_UPDATE}/campaign-copy/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          campaign_name_data: selectedCampaignName,
          ad_account: selectedAccount,
          fb_page: selectedPage,
          daily_budget: inputBudget,
          fb__link_for_fb: inputLinkForFB.replace('"', ""),
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.data || `HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully updated!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleCopyCampaign}>
            Copy campaign💨
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3}>
                <MDBox p={2} mb={3} display="flex" alignItems="center">
                  <img
                    src="https://www.svgrepo.com/show/492489/progress-11.svg"
                    alt="Image"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <MDTypography variant="h5">&nbsp;&nbsp;Copy Campaign</MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  mt={3}
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderTips(Tips)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={selectedCampaignName}
                          setSelectedCategory={setSelectedCampaignName}
                          categories={CampaignNamesAll}
                          category_name={"Campaign ID | Name"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <CustomDropdown
                          selectedCategory={selectedAccount}
                          setSelectedCategory={setSelectedAccount}
                          categories={accountsAll}
                          category_name={"FB - AD account"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4.5}>
                        <CustomDropdown
                          selectedCategory={selectedPage}
                          setSelectedCategory={setSelectedPage}
                          categories={pagesAll}
                          category_name={"FB - page"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={2.5}>
                        <CustomNumericInputField
                          inputValue={inputBudget}
                          setInputValue={setInputBudget}
                          label={"Daily budget (in $)"}
                          allowFloat={true}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <MDTypography variant="h6" color="text">
                          Fb__link_for_fb
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Button
                          variant="contained"
                          color="white"
                          onClick={handleOpenEditDialog}
                          fullWidth
                        >
                          Generate DA domain and FB link
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={inputLinkForFB}
                          setInputValue={setInputLinkForFB}
                          label={"https://"}
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/492516/wait-01-cursor.svg"
                            alt="Image"
                            style={{ width: "40px", height: "40px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;Campaign data for creation
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Edit Dialog */}
      <DialogFormGenerateDaDomain
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleCloseEditDialog}
        inputSearchTerm={inputSearchTerm}
        setInputSearchTerm={setInputSearchTerm}
        API_URL={"https://handle-links-1049948018753.us-central1.run.app"}
        setInputRedirectUrl={setInputLinkForFB}
        setResponseMessage={setInputLinkForFB}
        inputCt={inputCt}
        setInputCt={setInputCt}
        categoriesAll={categoriesAll}
      />
    </DashboardLayout>
  );
}

export default CopyCampaign;
