import React, { useState, useEffect } from "react";
import { Card, CircularProgress, TextField } from "@mui/material";

// eslint-disable-next-line react/prop-types
const ResponseMessage = ({ responseMessage, isLoading }) => {
  const [editableData, setEditableData] = useState([]);

  // Парсинг входящего JSON и установка данных
  const parseResponseMessage = (responseMessage) => {
    try {
      const parsedData = JSON.parse(responseMessage);
      setEditableData(parsedData);
    } catch (error) {
      console.error("Failed to parse responseMessage", error);
    }
  };

  // Обновляем данные, когда приходит новый responseMessage
  useEffect(() => {
    if (responseMessage) {
      parseResponseMessage(responseMessage);
    }
  }, [responseMessage]);

  // Обновление ключевых слов при редактировании
  const handleKeywordChange = (rowIndex, newValue) => {
    setEditableData((prevData) =>
      prevData.map((item, index) =>
        index === rowIndex ? { ...item, keywords: newValue.split("\n") } : item
      )
    );
  };

  return (
    <div className="response-message-container">
      {isLoading ? (
        <div className="loading-spinner">
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card className="response-message" sx={{ overflow: "auto" }}>
          {editableData.length > 0 ? (
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>Main Keyword</th>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>Type</th>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>Keywords</th>
                </tr>
              </thead>
              <tbody>
                {editableData.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {item.main_keyword}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.type}</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px", verticalAlign: "top" }}>
                      <TextField
                        multiline
                        fullWidth
                        value={
                          Array.isArray(item.keywords)
                            ? item.keywords.join("\n")
                            : item.keywords || ""
                        }
                        onChange={(e) => handleKeywordChange(rowIndex, e.target.value)}
                        placeholder="Add keywords (one per line)"
                        variant="outlined"
                        size="small"
                        spellCheck={false} // Отключает красные подчеркивания
                        InputProps={{
                          style: {
                            minHeight: "150px", // Высота соответствует примерно 10 строкам
                            overflowY: "auto", // Добавляем скроллинг
                            resize: "none", // Отключаем возможность изменения размера
                          },
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
        </Card>
      )}
    </div>
  );
};

export default ResponseMessage;
