import MDBox from "../../../components/MDBox";
import Icon from "@mui/material/Icon";
import { useRef } from "react";
import PropTypes from "prop-types";

function DataTableHeadCell1({ width, children, sorted, align, disableSortBy, ...rest }) {
  const textRef = useRef(null);

  return (
    <MDBox
      component="th"
      width={width || "auto"}
      py={1.5}
      px={3}
      sx={{
        minWidth: width || "1px",
        padding: 0,
        margin: "5px", // Небольшой отступ вокруг
      }}
    >
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="space-between" // Добавлено для распределения текста и иконки по краям
        width="100%"
        height="100%"
        py={1.5} // Высота хедера
        px={1} // Ширина хедера??
        sx={({ palette: { light }, borders: { borderWidth } }) => ({
          borderBottom: `${borderWidth[1]} solid ${light.main}`,
          // backgroundColor: "rgb(185,182,182)",
          backgroundColor: "rgba(66,66,74,0.71)",
          borderRadius: "8px", // Закругленные углы
          border: "1px solid black", // Черная рамка
          // resize: "horizontal", // Добавлено для изменения ширины
          // overflow: "auto", // Добавлено для отображения полос прокрутки при изменении размера
          // minWidth: width, // Минимальная ширина, чтобы элемент не стал слишком маленьким
        })}
      >
        <MDBox
          ref={textRef}
          {...rest}
          position="relative"
          textAlign={align}
          color="black"
          // color="white"
          opacity={1}
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            cursor: disableSortBy ? "default" : sorted && "pointer",
            userSelect: disableSortBy ? "none" : sorted && "none",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexGrow: 1, // Добавлено для расширения текста
          })}
        >
          {children}
        </MDBox>
        {!disableSortBy && sorted && (
          <MDBox
            position="relative" // Изменено с absolute на relative
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
              ml: 1, // Отступ слева
            })}
          >
            <MDBox
              position="absolute"
              top={-6}
              color={sorted === "asce" ? "text" : "secondary"}
              opacity={sorted === "asce" ? 1 : 0.5}
            >
              <Icon>arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={0}
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon>arrow_drop_down</Icon>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

DataTableHeadCell1.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

DataTableHeadCell1.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
  disableSortBy: PropTypes.string,
};

export default DataTableHeadCell1;
