import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import OptionsCheckboxListRule from "../dialog_checkboxes_rule_actions";

const DialogFormRule = ({
  openEditDialog,
  handleEditDialogClose,
  handleSave,
  selectedRow,
  userRole,
  userName,
  handleValueChange,
  userValue1,
  userValue2,
  userValue3,
  userValue4,
  userValue5,
  userValue6,
  userAction1Value,
  userNotifyOnly,
  tempValue1,
  tempValue2,
  tempValue3,
  tempValue4,
  tempValue5,
  tempValue6,
  tempAction1Value,
  tempNotifyOnly,
  tempActionOnly,
  userActionOnly,
  Tips,
}) => {
  return (
    <Dialog
      open={openEditDialog}
      onClose={handleEditDialogClose}
      // maxWidth="lg"
      maxWidth={false}
      fullWidth
      PaperProps={{
        style: {
          width: "70%",
          height: "70%",
        },
      }}
    >
      <DialogTitle>Edit Rule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <pre>
            <b>
              {selectedRow ? (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedRow.condition1 && (
                      <>
                        {selectedRow.condition1}

                        {(userRole === "admin"
                          ? tempValue1 !== undefined
                          : userValue1[userName] !== undefined) && (
                          <TextField
                            value={userRole === "admin" ? tempValue1 : userValue1[userName]}
                            onChange={(e) => handleValueChange("setValue1", e.target.value)}
                            variant="standard"
                            size="small"
                            type="number"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                    {selectedRow.condition2 && (
                      <>
                        {selectedRow.condition2}

                        {(userRole === "admin"
                          ? tempValue2 !== undefined
                          : userValue2[userName] !== undefined) && (
                          <TextField
                            value={userRole === "admin" ? tempValue2 : userValue2[userName]}
                            onChange={(e) => handleValueChange("setValue2", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                    {selectedRow.condition3 && (
                      <>
                        {selectedRow.condition3}

                        {(userRole === "admin"
                          ? tempValue3 !== undefined
                          : userValue3[userName] !== undefined) && (
                          <TextField
                            value={userRole === "admin" ? tempValue3 : userValue3[userName]}
                            onChange={(e) => handleValueChange("setValue3", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedRow.condition4}

                    {selectedRow.condition4 && (
                      <>
                        {selectedRow.condition4}

                        {(userRole === "admin"
                          ? tempValue4 !== undefined
                          : userValue4[userName] !== undefined) && (
                          <TextField
                            value={userRole === "admin" ? tempValue4 : userValue4[userName]}
                            onChange={(e) => handleValueChange("setValue4", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                    {selectedRow.condition5 && (
                      <>
                        {selectedRow.condition5}

                        {(userRole === "admin"
                          ? tempValue5 !== undefined
                          : userValue5[userName] !== undefined) && (
                          <TextField
                            value={userRole === "admin" ? tempValue5 : userValue5[userName]}
                            onChange={(e) => handleValueChange("setValue5", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                    {selectedRow.condition6 && (
                      <>
                        {selectedRow.condition6}

                        {(userRole === "admin"
                          ? tempValue6 !== undefined
                          : userValue6[userName] !== undefined) && (
                          <TextField
                            value={userRole === "admin" ? tempValue6 : userValue6[userName]}
                            onChange={(e) => handleValueChange("setValue6", e.target.value)}
                            variant="standard"
                            size="small"
                            style={{ width: "6%" }}
                          />
                        )}
                      </>
                    )}
                    {selectedRow.condition7}
                  </div>
                  <br />
                  {selectedRow.action1 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
                        alt="Red Arrow"
                        style={{ width: "24px", height: "24px", marginRight: "20px" }}
                      />
                      {selectedRow.action1}

                      {selectedRow.action1.includes("/ если") && (
                        <>
                          <TextField
                            value={
                              userRole === "admin" ? tempAction1Value : userAction1Value[userName]
                            }
                            onChange={(e) => handleValueChange("setActionValue", e.target.value)}
                            variant="standard"
                            size="small"
                            type="number"
                            style={{ width: "6%" }}
                          />
                        </>
                      )}
                      {selectedRow.action2}
                      {userRole === "admin" ? tempAction1Value : userAction1Value[userName]}
                    </div>
                  )}
                  <br />
                  <OptionsCheckboxListRule
                    userRole={userRole}
                    userName={userName}
                    userNotifyOnly={userNotifyOnly}
                    userActionOnly={userActionOnly}
                    tempNotifyOnly={tempNotifyOnly}
                    tempActionOnly={tempActionOnly}
                    handleValueChange={handleValueChange}
                  />
                </>
              ) : (
                <div>No data available</div>
              )}
            </b>
          </pre>
          <MDBox mb={1} mt={4}>
            <MDTypography variant="body3" color="text">
              Follow these tips to ensure correct ROI/Budget/Spend setup
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {Tips}
            </MDBox>
          </MDBox>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormRule;

DialogFormRule.propTypes = {
  openEditDialog: PropTypes.any,
  handleEditDialogClose: PropTypes.any,
  handleSave: PropTypes.any,
  selectedRow: PropTypes.any,
  userRole: PropTypes.any,
  userName: PropTypes.any,
  handleValueChange: PropTypes.any,
  userValue1: PropTypes.any,
  userValue2: PropTypes.any,
  userValue3: PropTypes.any,
  userValue4: PropTypes.any,
  userValue5: PropTypes.any,
  userValue6: PropTypes.any,
  userAction1Value: PropTypes.any,
  userNotifyOnly: PropTypes.any,
  tempValue1: PropTypes.any,
  tempValue2: PropTypes.any,
  tempValue3: PropTypes.any,
  tempValue4: PropTypes.any,
  tempValue5: PropTypes.any,
  tempValue6: PropTypes.any,
  tempAction1Value: PropTypes.any,
  tempNotifyOnly: PropTypes.any,
  tempActionOnly: PropTypes.any,
  userActionOnly: PropTypes.any,
  Tips: PropTypes.any,
};
