import React, { useEffect } from "react";
import { Box, FormControl, Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";

const CustomDropdownInput = ({ selectedValue, setSelectedValue, options, label, required }) => {
  const [touched, setTouched] = React.useState(false);

  useEffect(() => {
    if (required) {
      setTouched(true);
    }
  }, [required]);

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb={2}
      sx={{
        gap: 2,
        width: "100%",
      }}
    >
      <FormControl
        variant="standard"
        sx={{ width: "100%" }}
        required={required}
        error={required && touched && !selectedValue}
      >
        <Autocomplete
          value={selectedValue}
          onChange={(event, newValue) => {
            setSelectedValue(newValue || ""); // Присваиваем значение, либо пустую строку
          }}
          onBlur={handleBlur}
          freeSolo
          options={options}
          onInputChange={(event, newInputValue) => {
            setSelectedValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="standard"
              required={required}
              error={required && touched && !selectedValue}
              helperText={
                required && touched && !selectedValue
                  ? `Please select or enter a ${label.toLowerCase()}`
                  : ""
              }
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

CustomDropdownInput.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default CustomDropdownInput;
