import React from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import { Divider } from "@mui/material";

const OptionsCheckboxListRule = ({
  userRole,
  userName,
  userNotifyOnly,
  userActionOnly,
  handleValueChange,
  tempNotifyOnly,
  tempActionOnly,
}) => {
  const handleNotifyChange = (event) => {
    const value = event.target.checked;
    if (userRole === "admin") {
      handleValueChange("setNotifyOnly", value); // Обновляем состояние для админа
      console.log(`Value changing to`, value);
    } else {
      handleValueChange("setNotifyOnly", value); // Обновляем состояние для пользователя
      console.log(`Value changing to`, value);
    }
  };

  const handleActionChange = (event) => {
    const value = event.target.checked;
    if (userRole === "admin") {
      handleValueChange("setActionOnly", value); // Обновляем состояние для админа
    } else {
      handleValueChange("setActionOnly", value); // Обновляем состояние для пользователя
    }
  };

  return (
    <div>
      <Divider style={{ margin: "16px 0", height: "10px" }} />
      <pre style={{ marginBottom: "16px", marginTop: "20px" }}>
        <MDBox mb={3} display="flex" alignItems="center">
          <img
            src="https://www.svgrepo.com/show/218767/warning-error.svg"
            alt="Red Arrow"
            style={{ width: "25px", height: "25px" }}
          />
          <MDTypography variant="h6">
            &nbsp;&nbsp;Опции работы правила (Правило не активно без выбранной опции)
          </MDTypography>
        </MDBox>
      </pre>
      <FormControlLabel
        control={
          <Checkbox
            checked={userRole === "admin" ? tempNotifyOnly : userNotifyOnly[userName]}
            onChange={handleNotifyChange}
            color="primary"
          />
        }
        label={
          <pre>
            Уведомление – правило не будет выполнять никаких действий, но отправит уведомление и
            запишет в логи.
          </pre>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={userRole === "admin" ? tempActionOnly : userActionOnly[userName]}
            onChange={handleActionChange}
            color="primary"
          />
        }
        label={
          <pre>
            Выполнить действие – правило выполнит действие и запишет результат в логи, но не будет
            отправлять уведомлений.
          </pre>
        }
      />
      <Divider style={{ margin: "5px 0", height: "10px" }} />
    </div>
  );
};

OptionsCheckboxListRule.propTypes = {
  userRole: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userNotifyOnly: PropTypes.object.isRequired,
  userActionOnly: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  tempNotifyOnly: PropTypes.bool.isRequired,
  tempActionOnly: PropTypes.bool.isRequired,
};

export default OptionsCheckboxListRule;
