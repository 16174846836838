import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import AccountCheckboxList from "../../data_accounts";
import CampaignCheckboxList from "../../data_campaigns";
import Box from "@mui/material/Box";
import MDTypography from "../../../../components/MDTypography";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useFilteredData, useTableDataAdsetLogs } from "../../adsets_logs";
import TextField from "@mui/material/TextField";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";
import { processRules, userNames, API_URL, budgetROITips, ruleTips4 } from "../constants";
import DialogFormRule from "../rule_dialog";

function StopLossS1_2024_12_09() {
  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [tableData1, setTableData1] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "10%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "58%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "30%" },
      // { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" },
      ...(userRole === "admin"
        ? [{ Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" }]
        : []),
    ],
    rows: [],
  });

  const [tableData2, setTableData2] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "10%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "58%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "30%" },
      // { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" },
      ...(userRole === "admin"
        ? [{ Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" }]
        : []),
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [tempValue1, setTempValue1] = useState("");
  const [tempValue2, setTempValue2] = useState("");
  const [tempValue3, setTempValue3] = useState("");
  const [tempValue4, setTempValue4] = useState("");
  const [tempValue5, setTempValue5] = useState("");
  const [tempValue6, setTempValue6] = useState("");
  const [tempNotifyOnly, setTempNotifyOnly] = useState("");
  const [tempActionOnly, setTempActionOnly] = useState("");
  const [tempAction1Value, setTempAction1Value] = useState("");

  const [tempValue1_USER1, setTempValue1_USER1] = useState("");
  const [tempValue2_USER1, setTempValue2_USER1] = useState("");
  const [tempValue3_USER1, setTempValue3_USER1] = useState("");
  const [tempValue4_USER1, setTempValue4_USER1] = useState("");
  const [tempValue5_USER1, setTempValue5_USER1] = useState("");
  const [tempNotifyOnly_USER1, setTempNotifyOnly_USER1] = useState("");
  const [tempActionOnly_USER1, setTempActionOnly_USER1] = useState("");
  const [tempAction1Value_USER1, setTempAction1Value_USER1] = useState("");

  const [tempValue1_USER2, setTempValue1_USER2] = useState("");
  const [tempValue2_USER2, setTempValue2_USER2] = useState("");
  const [tempValue3_USER2, setTempValue3_USER2] = useState("");
  const [tempValue4_USER2, setTempValue4_USER2] = useState("");
  const [tempValue5_USER2, setTempValue5_USER2] = useState("");
  const [tempNotifyOnly_USER2, setTempNotifyOnly_USER2] = useState("");
  const [tempActionOnly_USER2, setTempActionOnly_USER2] = useState("");
  const [tempAction1Value_USER2, setTempAction1Value_USER2] = useState("");

  const [tempValue1_USER3, setTempValue1_USER3] = useState("");
  const [tempValue2_USER3, setTempValue2_USER3] = useState("");
  const [tempValue3_USER3, setTempValue3_USER3] = useState("");
  const [tempValue4_USER3, setTempValue4_USER3] = useState("");
  const [tempValue5_USER3, setTempValue5_USER3] = useState("");
  const [tempNotifyOnly_USER3, setTempNotifyOnly_USER3] = useState("");
  const [tempActionOnly_USER3, setTempActionOnly_USER3] = useState("");
  const [tempAction1Value_USER3, setTempAction1Value_USER3] = useState("");

  const [tempValue1_USER4, setTempValue1_USER4] = useState("");
  const [tempValue2_USER4, setTempValue2_USER4] = useState("");
  const [tempValue3_USER4, setTempValue3_USER4] = useState("");
  const [tempValue4_USER4, setTempValue4_USER4] = useState("");
  const [tempValue5_USER4, setTempValue5_USER4] = useState("");
  const [tempNotifyOnly_USER4, setTempNotifyOnly_USER4] = useState("");
  const [tempActionOnly_USER4, setTempActionOnly_USER4] = useState("");
  const [tempAction1Value_USER4, setTempAction1Value_USER4] = useState("");

  const [tempValue1_USER5, setTempValue1_USER5] = useState("");
  const [tempValue2_USER5, setTempValue2_USER5] = useState("");
  const [tempValue3_USER5, setTempValue3_USER5] = useState("");
  const [tempValue4_USER5, setTempValue4_USER5] = useState("");
  const [tempValue5_USER5, setTempValue5_USER5] = useState("");
  const [tempNotifyOnly_USER5, setTempNotifyOnly_USER5] = useState("");
  const [tempActionOnly_USER5, setTempActionOnly_USER5] = useState("");
  const [tempAction1Value_USER5, setTempAction1Value_USER5] = useState("");

  const [tempValue1_USER6, setTempValue1_USER6] = useState("");
  const [tempValue2_USER6, setTempValue2_USER6] = useState("");
  const [tempValue3_USER6, setTempValue3_USER6] = useState("");
  const [tempValue4_USER6, setTempValue4_USER6] = useState("");
  const [tempValue5_USER6, setTempValue5_USER6] = useState("");
  const [tempNotifyOnly_USER6, setTempNotifyOnly_USER6] = useState("");
  const [tempActionOnly_USER6, setTempActionOnly_USER6] = useState("");
  const [tempAction1Value_USER6, setTempAction1Value_USER6] = useState("");

  const [globalRuleStatus, setGlobalRuleStatus] = useState(false);
  const [firstSetActivationHourProvider, setFirstSetActivationHourProvider] = useState("");
  const [firstSetActivationHourEstonia, setFirstSetActivationHourEstonia] = useState("");
  const [secondSetActivationHourProvider, setSecondSetActivationHourProvider] = useState("");
  const [secondSetActivationHourEstonia, setSecondSetActivationHourEstonia] = useState("");
  const [setRunInterval, setSetRunInterval] = useState("");

  const tableDataAdsetLogs = useTableDataAdsetLogs("stop_loss_1 (s1_");
  const filteredDataLogs = useFilteredData(tableDataAdsetLogs, search);

  const userName = userNames[userEmail];

  const ruleTips = [
    "Set the first activation time in the 24-hour format (S1 provider time).",
    "You can configure the interval between activations (in hours).",
    "You can specify the hour for campaign or adset activation in Los Angeles time (DA provider time).",
    `Estonia Activation Hour: ${firstSetActivationHourEstonia}:20`,
  ];

  const ruleTips2 = [
    "These rules are applied 2 times per hour: xx:20, xx:40",
    "To disable a rule, turn it off for all accounts or use the toggle.",
    "If you disable the accounts or campaigns listed below, this will apply to all rules in this set.",
    "When you edit a rule, you're only editing it for yourself; it won't affect other people's campaigns.",
    "Campaigns that include matches from the exclusion list will be automatically excluded (TEST_CAMPAIGN, Engagement campaign, COST_CAP, COSTCAP, BID_CAP, BIDCAP, GC-UTC-305, GC-UTC-304, - L0, (S1_), L1",
    "Specify the hour in 24-hour format for the rule to run.",
    "Adjust the interval to repeat the rule every 1 or 2 hours.",
    `Estonia Start Hour: ${secondSetActivationHourEstonia}:20`,
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/rules/stop_loss_S1_2024_12_09/`);

      const rows1 = processRules(
        response.data[0],
        userRole,
        userName,
        handleToggle,
        setFirstSetActivationHourProvider,
        setFirstSetActivationHourEstonia,
        setSetRunInterval,
        setGlobalRuleStatus,
        handleEditClick,
        "S1"
      );

      const rows2 = processRules(
        response.data[1],
        userRole,
        userName,
        handleToggle,
        setSecondSetActivationHourProvider,
        setSecondSetActivationHourEstonia,
        setSetRunInterval,
        setGlobalRuleStatus,
        handleEditClick,
        "S1"
      );

      setTableData1((prevState) => ({ ...prevState, rows: rows1 }));
      setTableData2((prevState) => ({ ...prevState, rows: rows2 }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggle = useCallback(async (ruleName = "", columnName, currentStatus) => {
    const newStatus = currentStatus === true ? "Inactive" : "Active";
    console.log("Current status/new status:", currentStatus, newStatus);

    // Send request to update rule status
    const url = `${API_URL}/api/rules_stop_loss_S1_2024_12_09/update_status/`;

    try {
      console.log("rule_name/columnName/newStatus:", ruleName, columnName, newStatus);
      await axios.post(url, {
        rule_name: ruleName,
        column_name: columnName,
        status: newStatus,
        user_email: userEmail,
      });
      setTableData1((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_name === ruleName ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      setTableData2((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_name === ruleName ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      await fetchData();
    } catch (error) {
      toast.error(`Failed to update rule status for ID ${ruleName}.`);
    }
  }, []);

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);

    setTempValue1(row.value1);
    setTempValue2(row.value2);
    setTempValue3(row.value3);
    setTempValue4(row.value4);
    setTempValue5(row.value5);
    setTempValue5(row.value5);
    setTempValue6(row.value6);
    setTempNotifyOnly(row.notify_only);
    setTempActionOnly(row.action_only);
    setTempAction1Value(row.action1_value);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);

    setTempValue1("");
    setTempValue2("");
    setTempValue3("");
    setTempValue4("");
    setTempValue5("");
    setTempValue6("");
    setTempNotifyOnly("");
    setTempActionOnly("");
    setTempAction1Value("");
  };

  const handleSave = async () => {
    if (!selectedRow) return;
    const updatedData = {
      rule_name: selectedRow.rule_name,
      value1: tempValue1,
      value2: tempValue2,
      value3: tempValue3,
      value4: tempValue4,
      value5: tempValue5,
      value6: tempValue6,
      action1_value: tempAction1Value,
      action_only: tempActionOnly,
      notify_only: tempNotifyOnly,
      user_email: userEmail,
    };
    try {
      await axios.post(
        `${API_URL}/api/rules/update_rule_rules_stop_loss_S1_2024_12_09/`,
        updatedData
      );
      await fetchData();
      toast.success("Data updated successfully!");
      handleEditDialogClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const userFunctions = {
    USER1: {
      setUserValue1: setTempValue1_USER1,
      setUserValue2: setTempValue2_USER1,
      setUserValue3: setTempValue3_USER1,
      setUserValue4: setTempValue4_USER1,
      setUserValue5: setTempValue5_USER1,
      setUserNotifyOnly: setTempNotifyOnly_USER1,
      setUserActionValue: setTempAction1Value_USER1,
      setUserActionOnly: setTempActionOnly_USER1,
    },
    USER2: {
      setUserValue1: setTempValue1_USER2,
      setUserValue2: setTempValue2_USER2,
      setUserValue3: setTempValue3_USER2,
      setUserValue4: setTempValue4_USER2,
      setUserValue5: setTempValue5_USER2,
      setUserNotifyOnly: setTempNotifyOnly_USER2,
      setUserActionValue: setTempAction1Value_USER2,
      setUserActionOnly: setTempActionOnly_USER2,
    },
    USER3: {
      setUserValue1: setTempValue1_USER3,
      setUserValue2: setTempValue2_USER3,
      setUserValue3: setTempValue3_USER3,
      setUserValue4: setTempValue4_USER3,
      setUserValue5: setTempValue5_USER3,
      setUserNotifyOnly: setTempNotifyOnly_USER3,
      setUserActionValue: setTempAction1Value_USER3,
      setUserActionOnly: setTempActionOnly_USER3,
    },
    USER4: {
      setUserValue1: setTempValue1_USER4,
      setUserValue2: setTempValue2_USER4,
      setUserValue3: setTempValue3_USER4,
      setUserValue4: setTempValue4_USER4,
      setUserValue5: setTempValue5_USER4,
      setUserNotifyOnly: setTempNotifyOnly_USER4,
      setUserActionValue: setTempAction1Value_USER4,
      setUserActionOnly: setTempActionOnly_USER4,
    },
    USER5: {
      setUserValue1: setTempValue1_USER5,
      setUserValue2: setTempValue2_USER5,
      setUserValue3: setTempValue3_USER5,
      setUserValue4: setTempValue4_USER5,
      setUserValue5: setTempValue5_USER5,
      setUserNotifyOnly: setTempNotifyOnly_USER5,
      setUserActionValue: setTempAction1Value_USER5,
      setUserActionOnly: setTempActionOnly_USER5,
    },
    USER6: {
      setUserValue1: setTempValue1_USER6,
      setUserValue2: setTempValue2_USER6,
      setUserValue3: setTempValue3_USER6,
      setUserValue4: setTempValue4_USER6,
      setUserValue5: setTempValue5_USER6,
      setUserNotifyOnly: setTempNotifyOnly_USER6,
      setUserActionValue: setTempAction1Value_USER6,
      setUserActionOnly: setTempActionOnly_USER6,
    },
  };

  const handleValueChange = (field, value) => {
    if (userRole === "admin") {
      switch (field) {
        case "setValue1":
          setTempValue1(value);
          break;
        case "setValue2":
          setTempValue2(value);
          break;
        case "setValue3":
          setTempValue3(value);
          break;
        case "setValue4":
          setTempValue4(value);
          break;
        case "setValue5":
          setTempValue5(value);
          break;
        case "setValue6":
          setTempValue5(value);
          break;
        case "setNotifyOnly":
          setTempNotifyOnly(value);
          break;
        case "setActionOnly":
          setTempActionOnly(value);
          break;
        case "setActionValue":
          setTempAction1Value(value);
          break;
        default:
          break;
      }
    } else {
      const userSetFunctions = userFunctions[userName];
      if (userSetFunctions) {
        switch (field) {
          case "setValue1":
            userSetFunctions.setUserValue1(value);
            break;
          case "setValue2":
            userSetFunctions.setUserValue2(value);
            break;
          case "setValue3":
            userSetFunctions.setUserValue3(value);
            break;
          case "setValue4":
            userSetFunctions.setUserValue4(value);
            break;
          case "setValue5":
            userSetFunctions.setUserValue5(value);
            break;
          case "setValue6":
            userSetFunctions.setUserValue5(value);
            break;
          case "setNotifyOnly":
            userSetFunctions.setUserNotifyOnly(value);
            break;
          case "setActionOnly":
            userSetFunctions.setUserActionOnly(value);
            break;
          case "setAction":
            userSetFunctions.setUserAction(value);
            break;
          case "setActionValue":
            userSetFunctions.setUserActionValue(value);
            break;
          default:
            break;
        }
      }
    }
  };

  const userValue1 = {
    USER1: tempValue1_USER1,
    USER2: tempValue1_USER2,
    USER3: tempValue1_USER3,
    USER4: tempValue1_USER4,
    USER5: tempValue1_USER5,
    USER6: tempValue1_USER6,
  };

  const userValue2 = {
    USER1: tempValue2_USER1,
    USER2: tempValue2_USER2,
    USER3: tempValue2_USER3,
    USER4: tempValue2_USER4,
    USER5: tempValue2_USER5,
    USER6: tempValue2_USER6,
  };

  const userValue3 = {
    USER1: tempValue3_USER1,
    USER2: tempValue3_USER2,
    USER3: tempValue3_USER3,
    USER4: tempValue3_USER4,
    USER5: tempValue3_USER5,
    USER6: tempValue3_USER6,
  };

  const userValue4 = {
    USER1: tempValue4_USER1,
    USER2: tempValue4_USER2,
    USER3: tempValue4_USER3,
    USER4: tempValue4_USER4,
    USER5: tempValue4_USER5,
    USER6: tempValue4_USER6,
  };

  const userValue5 = {
    USER1: tempValue5_USER1,
    USER2: tempValue5_USER2,
    USER3: tempValue5_USER3,
    USER4: tempValue5_USER4,
    USER5: tempValue5_USER5,
    USER6: tempValue5_USER6,
  };

  const userNotifyOnly = {
    USER1: tempNotifyOnly_USER1,
    USER2: tempNotifyOnly_USER2,
    USER3: tempNotifyOnly_USER3,
    USER4: tempNotifyOnly_USER4,
    USER5: tempNotifyOnly_USER5,
    USER6: tempNotifyOnly_USER6,
  };

  const userActionOnly = {
    USER1: tempActionOnly_USER1,
    USER2: tempActionOnly_USER2,
    USER3: tempActionOnly_USER3,
    USER4: tempActionOnly_USER4,
    USER5: tempActionOnly_USER5,
    USER6: tempActionOnly_USER6,
  };

  const userActionValue = {
    USER1: tempAction1Value_USER1,
    USER2: tempAction1Value_USER2,
    USER3: tempAction1Value_USER3,
    USER4: tempAction1Value_USER4,
    USER5: tempAction1Value_USER5,
    USER6: tempAction1Value_USER6,
  };

  const handleSubmitForm = async () => {
    const data = {
      column_name_first_set_activation_hour:
        userRole === "admin"
          ? "first_set_activation_hour"
          : `first_set_activation_hour_${userName}`,
      first_set_activation_hour: firstSetActivationHourProvider,
      column_name_interval:
        userRole === "admin" ? "set_run_interval" : `set_run_interval_${userName}`,
      set_run_interval: setRunInterval,
      column_name_second_set_activation_hour:
        userRole === "admin"
          ? "second_set_activation_hour"
          : `second_set_activation_hour_${userName}`,
      second_set_activation_hour: secondSetActivationHourProvider,
    };

    try {
      const response = await axios.post(
        `${API_URL}/api/rules/update_interval_or_activation_top_loss_S1_2024_12_09/`,
        data
      );
      console.log("Response:", response.data);
      toast.success(`Success!`);
      await fetchData();
    } catch (error) {
      console.error("Error updating rule activation hour 1:", error);
      toast.error(`Failed to update hour`);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={globalRuleStatus}
                  onChange={() => {
                    setGlobalRuleStatus(!globalRuleStatus);
                    // handleToggle(
                    //   "stop_loss_1_2024_12_09",
                    //   userRole === "admin"
                    //     ? "global_rule_status"
                    //     : `global_rule_status_${userName}`,
                    //   globalRuleStatus
                    // );
                    handleToggle("stop_loss_1_2024_12_09", globalRuleStatus, globalRuleStatus);
                  }}
                  disabled={userRole !== "admin"}
                />
              }
              label={`stop_loss_1 - ${globalRuleStatus ? "Active" : "Paused"}`}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Activation</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips)}
              </MDBox>
            </MDBox>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1} ml={2}>
              <span
                style={{
                  display: "list-item",
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                (S1 time - UTC) Activate campaign / adset hour:&nbsp;&nbsp;
              </span>
              <TextField
                value={firstSetActivationHourProvider}
                onChange={(e) => setFirstSetActivationHourProvider(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "4%" }}
                disabled={userRole !== "admin"}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                :20&nbsp;&nbsp;
              </span>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1}>
              {userRole && userRole === "admin" && (
                <Button
                  onClick={handleSubmitForm}
                  startIcon={<SaveIcon />}
                  style={{
                    marginLeft: "-20px",
                    color: "black",
                  }}
                >
                  Save
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData1} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Activation / Deactivation</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips2)}
              </MDBox>
            </MDBox>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1} ml={2}>
              <span
                style={{
                  display: "list-item",
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                (S1 time - UTC) First rule run starts at&nbsp;&nbsp;
              </span>
              <TextField
                value={secondSetActivationHourProvider}
                onChange={(e) => setSecondSetActivationHourProvider(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "2%" }}
                disabled={userRole !== "admin"}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                :20
              </span>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1}>
              {userRole && userRole === "admin" && (
                <Button
                  onClick={handleSubmitForm}
                  startIcon={<SaveIcon />}
                  style={{
                    marginLeft: "-20px",
                    color: "black",
                  }}
                >
                  Save
                </Button>
              )}
            </Box>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips4)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData2} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <AccountCheckboxList
                ruleName="stop_loss_s1"
                fetchApiEndpoint={`${API_URL}/api/rules/get_accounts_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <CampaignCheckboxList
                ruleName="stop_loss_s1"
                fetchApiEndpoint={`${API_URL}/api/rules/get_campaigns_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableDataAdsetLogs} canSearch entriesPerPage={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/*Edit Dialog */}
      <DialogFormRule
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleEditDialogClose}
        handleSave={handleSave}
        selectedRow={selectedRow}
        userRole={userRole}
        userName={userName}
        handleValueChange={handleValueChange}
        userValue1={userValue1}
        userValue2={userValue2}
        userValue3={userValue3}
        userValue4={userValue4}
        userValue5={userValue5}
        userAction1Value={userActionValue}
        userNotifyOnly={userNotifyOnly}
        tempValue1={tempValue1}
        tempValue2={tempValue2}
        tempValue3={tempValue3}
        tempValue4={tempValue4}
        tempValue5={tempValue5}
        tempValue6={tempValue6}
        tempAction1Value={tempAction1Value}
        tempNotifyOnly={tempNotifyOnly}
        tempActionOnly={tempActionOnly}
        userActionOnly={userActionOnly}
        Tips={renderTips(budgetROITips)}
      />
    </DashboardLayout>
  );
}

export default StopLossS1_2024_12_09;
