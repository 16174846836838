import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import DataTable from "../../examples/Tables/DataTable";
import "react-toastify/dist/ReactToastify.css";

const preStyle = {
  whiteSpace: "pre-wrap", // Позволяет тексту переноситься
  wordWrap: "break-word", // Разрывает длинные слова
};

const API_URL = "https://dashboard2-1049948018753.us-central1.run.app";

export const adsetLogsTips = [
  "Logs are updated every hour.",
  "In the 'Results now' campaign/adset columns, you can see the latest data update time.",
  "Data is updated for campaigns and ad sets that are present in the daily reports.",
  "If a campaign or ad set is not in the daily report, the 'Last update' will not refresh for it.",
  "The 'update_time' column reflects the time when the rule was triggered.",
];

export function useTableDataAdsetLogs(namePattern) {
  const [tableDataAdsetLogs, setTableDataAdsetLogs] = useState({
    columns: [
      { Header: "update_time", accessor: "update_time", width: "10%" },
      { Header: "rule_name", accessor: "rule_name", width: "10%" },
      { Header: "reason", accessor: "reason", width: "10%" },
      { Header: "account_id", accessor: "account_id", width: "10%" },
      { Header: "campaign", accessor: "campaign", width: "10%" },
      { Header: "adset", accessor: "adset", width: "10%" },
      { Header: "ad", accessor: "ad", width: "10%" },
      { Header: "before", accessor: "before", width: "10%" },
      { Header: "after", accessor: "after", width: "10%" },
      { Header: "Results before campaign", accessor: "before_campaign", width: "10%" },
      { Header: "Results now campaign", accessor: "current_data_campaign", width: "10%" },
      { Header: "Results before adset", accessor: "before_adset", width: "10%" },
      { Header: "Results now adset", accessor: "current_data_adset", width: "10%" },
      { Header: "Results before ad", accessor: "before_ad", width: "10%" },
      { Header: "Results now ad", accessor: "current_data_ad", width: "10%" },
    ],
    rows: [],
  });

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userEmail = localStorage.getItem("userEmail");
        const response = await axios.post(
          `${API_URL}/api/campaigns/get_historical_data_adsets_under_rule/`,
          { name_pattern: namePattern, user_email: userEmail }
        );
        const rows = response.data.map((rule) => ({
          update_time: rule.update_time,
          status: rule.status,
          rule_name: rule.rule_name,
          reason: (
            <pre>
              {rule.reason
                .split(/\s*,\s*\\?n\s*/g) // Разбиваем по запятым и любым вариациям \n
                .map((item, index) => (
                  <React.Fragment key={index}>
                    {item.trim()}
                    <br />
                  </React.Fragment>
                ))}
            </pre>
          ),
          provider: rule.provider,
          paused_count: rule.paused_count,
          campaign: (
            <pre style={preStyle}>
              {rule.campaign_name}
              <br />
              <br />({rule.camp_id})
            </pre>
          ),
          adset: (
            <pre style={preStyle}>
              {rule.adset_name}
              <br />
              <br />({rule.adset_id})
            </pre>
          ),
          ad: (
            <pre style={preStyle}>
              {rule.ad_name}
              <br />
              <br />({rule.ad_id})
            </pre>
          ),
          before: (
            <pre>
              Ad Status: {rule.ad_status_before}
              <br />
              <br />
              Adset Budget: {rule.adset_budget_before}
              <br />
              Adset BID: {rule.bid_before}
              <br />
              Adset Status: {rule.adset_status_before}
              <br />
              <br />
              Campaign Budget: {rule.campaign_budget_before}
              <br />
              Campaign Status: {rule.status}
            </pre>
          ),
          after: (
            <pre>
              Ad Status: {rule.ad_status_after}
              <br />
              <br />
              Adset Budget: {rule.adset_budget_after}
              <br />
              Adset BID: {rule.bid_after}
              <br />
              Adset Status: {rule.adset_status_after}
              <br />
              <br />
              Campaign Budget: {rule.campaign_budget_after}
              <br />
              Campaign Status: {rule.status_after}
            </pre>
          ),
          account_id: (
            <pre>
              {rule.account_id.split(", ").map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </pre>
          ),
          adset_budget_before: rule.adset_budget_before,
          adset_budget_after: rule.adset_budget_after,
          bid_before: rule.bid_before,
          bid_after: rule.bid_after,
          ad_name: rule.ad_name,
          ad_id: rule.ad_id,
          adset_status_before: rule.adset_status_before,
          adset_status_after: rule.adset_status_after,
          campaign_budget_before: rule.campaign_budget_before,
          campaign_budget_after: rule.campaign_budget_after,
          status_after: rule.status_after,
          before_campaign:
            rule.before_campaign && rule.before_campaign.trim() !== "" ? (
              <pre>
                {rule.before_campaign.split(", ").map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </pre>
            ) : null,
          before_adset:
            rule.before_adset && rule.before_adset.trim() !== "" ? (
              <pre>
                {rule.before_adset.split(", ").map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </pre>
            ) : null,
          before_ad:
            rule.before_ad && rule.before_ad.trim() !== "" ? (
              <pre>
                {rule.before_ad.split(", ").map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </pre>
            ) : null,
          current_data_campaign:
            rule.current_data_campaign && rule.current_data_campaign.trim() !== "" ? (
              <pre>
                {rule.current_data_campaign.split(", ").map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </pre>
            ) : null,

          current_data_adset:
            rule.current_data_adset && rule.current_data_adset.trim() !== "" ? (
              <pre>
                {rule.current_data_adset.split(", ").map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </pre>
            ) : null,
          current_data_ad:
            rule.current_data_ad && rule.current_data_ad.trim() !== "" ? (
              <pre>
                {rule.current_data_ad.split(", ").map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </pre>
            ) : null,
        }));
        setTableDataAdsetLogs((prevState) => ({ ...prevState, rows }));
        setFilteredData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return tableDataAdsetLogs;
}

export function useFilteredData(tableDataAdsetLogs, search) {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(
      tableDataAdsetLogs.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableDataAdsetLogs.rows]);

  return filteredData;
}

export function handleSearchChange(event, setSearch) {
  setSearch(event.target.value);
}

function AdsetsUnderRule() {
  const [search, setSearch] = useState("");
  const tableDataAdsetLogs = useTableDataAdsetLogs();
  const filteredData = useFilteredData(tableDataAdsetLogs, search);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={filteredData} canSearch entriesPerPage={false} />
            </Paper>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdsetsUnderRule;
