import React, { useEffect, useState } from "react";
import MDTypography from "../../../../components/MDTypography";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomInputField from "CUSTOM_COMPONENTS/custom_input_field";
import CustomDropdown from "CUSTOM_COMPONENTS/custom_dropdown";
import CustomInputFieldMultiline from "CUSTOM_COMPONENTS/custom_input_field_multiline";
import CustomNumericInputField from "CUSTOM_COMPONENTS/custom_numeric_input_field";
import ReusableButton from "../button";
import HintComponent from "../hint";

function CreateKWLIST() {
  const API_URL = "https://kw-app-tmgx45bhsa-uc.a.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [selectedName, setSelectedName] = useState("");
  const namesAll = ["Alex", "Anton", "Artem", "Vlad", "Andrei", "Marina"];

  const [inputCampaignID, setInputCampaignID] = useState("");
  const [inputCampaignIDGetKW, setInputCampaignIDGetKW] = useState("");
  const [inputSetNumber, setInputSetNumber] = useState("");
  const [inputKWLIST, setInputKWLIST] = useState("");
  const [inputKWPercentage, setInputKWPercentage] = useState("");

  const userEmail = localStorage.getItem("userEmail");

  const handleCreateKWLIST = () => {
    if (!inputKWLIST || !selectedName || !inputCampaignID || !inputSetNumber) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL}/create_kw_list_for_campaign/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        kw_list_data: inputKWLIST,
        md_name: selectedName,
        campaign_id: inputCampaignID,
        set_number: inputSetNumber,
        user_email: userEmail,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("KW-LIST successfully created!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetKWLIST = () => {
    if (!inputCampaignIDGetKW) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL}/get_kw_lists/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        campaign_id: inputCampaignIDGetKW,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("KW-LIST successfully received!");

        // Формируем строку для inputKWPercentage
        const formattedPercentage = Object.keys(data.response)
          .map((key) => {
            const list = data.response[key];
            return `${list.keyword_list_id}_${list.percentage}%`;
          })
          .join("\n");

        // Обновляем состояние inputKWPercentage
        setInputKWPercentage(formattedPercentage);
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateKWLIST = () => {
    if (!inputKWPercentage) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL}/update_percentage/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        keyword_lists: inputKWPercentage,
        campaign_id: inputCampaignID,
        user_email: userEmail,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("KW-LIST percentage successfully updated!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={2}>
                <MDTypography variant="h5">KW-list creation/settings 🔑</MDTypography>
              </MDBox>

              <MDBox
                p={2}
                mb={1}
                display="flex"
                alignItems="center"
                gap={1} // Adds space between text and hint component
              >
                <MDTypography variant="h6" sx={{ fontWeight: "bold" }}>
                  Help -&gt;
                </MDTypography>
                <HintComponent />
              </MDBox>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MDBox p={2}>
                    <MDTypography variant="h6" sx={{ color: "grey" }}>
                      Create KW-list
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={selectedName}
                          setSelectedCategory={setSelectedName}
                          categories={namesAll}
                          category_name={"Media Buyer Name"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomNumericInputField
                          inputValue={inputSetNumber}
                          setInputValue={setInputSetNumber}
                          label={"Number of SET"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={inputCampaignID}
                          setInputValue={setInputCampaignID}
                          label={"Campaign ID"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={inputKWLIST}
                          setInputValue={setInputKWLIST}
                          label={"Keyword List"}
                          multiline
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <ReusableButton label="Create KW_LIST" onClickHandler={handleCreateKWLIST} />
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MDBox p={2}>
                    <MDTypography variant="h6" sx={{ color: "grey" }}>
                      Get KW info
                    </MDTypography>
                  </MDBox>

                  <MDBox p={2} display="flex" alignItems="center" gap={2}>
                    <CustomInputField
                      inputValue={inputCampaignIDGetKW}
                      setInputValue={setInputCampaignIDGetKW}
                      label={"Campaign ID"}
                      required={true}
                    />
                    <ReusableButton label="Get KW info" onClickHandler={handleGetKWLIST} />
                  </MDBox>

                  <MDBox p={2}>
                    <MDTypography variant="h6" sx={{ color: "grey" }}>
                      Update KW percentage
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={inputCampaignID}
                          setInputValue={setInputCampaignID}
                          label={"Campaign ID"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={inputKWPercentage}
                          setInputValue={setInputKWPercentage}
                          label={"KW List ID_%"}
                          multiline
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <ReusableButton label="Update percentage" onClickHandler={handleUpdateKWLIST} />
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateKWLIST;
