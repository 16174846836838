import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "CUSTOM_COMPONENTS/custom_dropdown";
import OptionsCheckboxList from "CUSTOM_COMPONENTS/options_checkbox";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";
import CustomInputField from "../../../CUSTOM_COMPONENTS/custom_input_field";
import { DeeplLanguages, testResponseData } from "./kw_constants";
import ResponseMessage from "./response_data";

function GenerateKW() {
  const API_URL_UPDATE = "https://kw-app-1049948018753.us-central1.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [selectedLanguage, setSelectedLanguage] = useState("EN - English");
  const [inputKeyword, setInputKeyword] = useState("");
  const keywordOptions = [
    "schedule_type_02",
    "city_100K",
    "city_200K",
    "country",
    "metro",
    "state",
    "state_name",
    "season_type_04",
    "demographic_type_06",
    "scarcity_type_09",
    "demographic_type_10",
    "around_me",
    "near_me",
    "nearby",
    "how_to_get",
    "how_to_start",
  ];
  const [selectedKeywordOptions, setSelectedKeywordOptions] = useState([]);

  const Tips = [
    "Please select at least 3 options to generate keywords. If there aren't enough options, no keywords will be generated.",
    "Keywords will only be generated if you select enough relevant options. Ensure you meet the required criteria.",
  ];

  const handleGenerateKw = () => {
    if (!selectedKeywordOptions || !inputKeyword || !selectedLanguage) {
      toast.error("Please fill all required fields");
      return;
    }
    setIsLoading(true);

    const dataDict = {
      keyword: inputKeyword,
      city: "{{city}}",
      city_100K: selectedKeywordOptions.includes("city_100K"),
      city_200K: selectedKeywordOptions.includes("city_200K"),
      country: selectedKeywordOptions.includes("country"),
      metro: selectedKeywordOptions.includes("metro"),
      state: selectedKeywordOptions.includes("state"),
      state_name: selectedKeywordOptions.includes("state_name"),
      schedule_type_02: true,
      season_type_04: selectedKeywordOptions.includes("season_type_04"),
      demographic_type_06: selectedKeywordOptions.includes("demographic_type_06"),
      scarcity_type_09: selectedKeywordOptions.includes("scarcity_type_09"),
      demographic_type_10: selectedKeywordOptions.includes("demographic_type_10"),
      around_me: selectedKeywordOptions.includes("around_me"),
      near_me: selectedKeywordOptions.includes("near_me"),
      nearby: selectedKeywordOptions.includes("nearby"),
      how_to_get: selectedKeywordOptions.includes("how_to_get"),
      how_to_start: selectedKeywordOptions.includes("how_to_start"),
      language: selectedLanguage.split(" - ")[0],
      user_email: userEmail,
    };
    console.log(dataDict);

    //   setResponseMessage(testResponseData);
    //   setIsLoading(false);
    // };

    fetch(`${API_URL_UPDATE}/generate_keywords/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ keyword_keys_dict: dataDict }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.data || `HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(data.json_data);
        toast.success("Keywords successfully generated!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleKeywordOptionsChange = (options) => {
    setSelectedKeywordOptions(options);
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleGenerateKw}>
            Generate KW💨
          </button>
        </div>
      </div>
    );
  };

  // const ResponseMessage = () => {
  //   return (
  //     <div className="response-message-container">
  //       {isLoading ? (
  //         <div className="loading-spinner">
  //           <CircularProgress color="success" />
  //         </div>
  //       ) : (
  //         <Card className="response-message" sx={{ overflow: "auto" }}>
  //           <pre>{responseMessage}</pre>
  //         </Card>
  //       )}
  //     </div>
  //   );
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3}>
                <MDBox p={2} mb={3} display="flex" alignItems="center">
                  <img
                    src="https://www.svgrepo.com/show/439015/transforms.svg"
                    alt="Image"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <MDTypography variant="h5">&nbsp;&nbsp;Generate KW</MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  mt={3}
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderTips(Tips)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={inputKeyword}
                          setInputValue={setInputKeyword}
                          label={"Keyword"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={selectedLanguage}
                          setSelectedCategory={setSelectedLanguage}
                          categories={DeeplLanguages}
                          category_name={"Language"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDBox mt={1} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/530115/leaves.svg"
                            alt="Image"
                            style={{ width: "35px", height: "35px" }}
                          />
                          <MDTypography variant="h6" color="text">
                            &nbsp;&nbsp;Select KW options
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <OptionsCheckboxList
                        onOptionsChange={handleKeywordOptionsChange}
                        typesAll={keywordOptions}
                        columns={4}
                      />
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                {/*<ResponseMessage />*/}
                <ResponseMessage responseMessage={responseMessage} isLoading={isLoading} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default GenerateKW;
