import React, { useEffect, useState } from "react";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MDBox from "../../components/MDBox";
import CustomInputFieldMultiline from "../../CUSTOM_COMPONENTS/custom_input_field_multiline";
import CustomInputField from "../../CUSTOM_COMPONENTS/custom_input_field";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";
import DialogFormS24V1 from "../RULES/s24/s_24_V_1_dialog";
import DialogFormGenerateDaDomain from "./DA_generate_domain_dialog";
import Box from "@mui/material/Box";
import GeneratedDomainsTAble from "./domains_table";
import { fetchCategories } from "./constants";

function GenerateLink() {
  const API_URL = "https://handle-links-1049948018753.us-central1.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const [categoriesAll, setCategoriesAll] = useState([]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchCategories();
        setCategoriesAll(data);
      } catch (error) {
        console.error("Failed to load categories:", error);
      }
    };

    loadCategories();
  }, []);

  const [inputSearchTerm, setInputSearchTerm] = useState("");
  const [inputRedirectUrl, setInputRedirectUrl] = useState("");
  const [inputCt, setInputCt] = useState("184");

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const isFormValid = () => {
    return (
      inputRedirectUrl.trim() !== "" &&
      inputCt.trim() !== "" &&
      inputSearchTerm.trim() !== "" &&
      inputRedirectUrl.includes("https://")
    );
  };

  const urlView = isFormValid()
    ? `${inputRedirectUrl}?ad_name={{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&ad_id={{ad.id}}&src=fbk&utm_source=facebook&utm_medium=paid&tg1={{adset.id}}&tg2={{ad.id}}&tg3={{campaign.id}}&tg4={{campaign.name}}&tg5={{placement}}&tg6={{adset.name}}&tg7={{ad.id}}&tg8={{site_source_name}}&ct=${inputCt}&atxt=Search for ${inputSearchTerm}`
    : "";

  const Tips = [
    "To create a new link, fill in the fields below:",
    "Search Term: Enter the search query you want to use. This is the text that users will search for.",
    "Redirect URL: Enter the URL where users will be redirected after performing the search.",
    "After filling in all fields, click the Generate Link button. The generated link will appear below, and you can copy it for further use.",
    "Copy link without quotes",
    "ДА линк на шаблоны: https://miro.com/app/board/uXjVKzrb7sM=/",
  ];

  const Tips2 = [
    "Insert an existing domain or generate a new one using the button.",
    "--------------------------------------------------------------------------------------------------->.",
  ];

  const handleGenerateLink = () => {
    if (!inputSearchTerm || !inputRedirectUrl) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL}/generate_link/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        search_term: inputSearchTerm,
        redirect_url: inputRedirectUrl,
        ct: inputCt,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Link successfully generated!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleGenerateLink}>
            Generate Link💨
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    const handleCopy = () => {
      const trimmedResponse = responseMessage.slice(1, -1); // Удаление первого и последнего символа
      navigator.clipboard.writeText(trimmedResponse);
    };

    return (
      <div className="response-message-container">
        <Button
          onClick={handleCopy}
          size="large"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            backgroundColor: "rgba(255,255,255,0.7)",
            minWidth: "unset",
            padding: "20px",
            borderRadius: "4px",
            color: "rgba(0,0,0,0.7)",
          }}
        >
          <ContentCopyIcon fontSize="large" />
        </Button>
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3}>
                <MDBox p={0} mb={3} display="flex" alignItems="center">
                  <img
                    src="https://www.svgrepo.com/show/271745/monitor-link.svg"
                    alt="Red Arrow"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <MDTypography variant="h5">&nbsp;&nbsp;DA URL Construction Form</MDTypography>
                </MDBox>
                <MDBox p={0} mb={3} display="flex" alignItems="center">
                  <img
                    src="https://i.postimg.cc/zDCC8bJh/photo-2024-10-17-16-33-15.jpg"
                    alt="Instruction"
                    style={{ width: "100%", height: "auto" }}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  mt={3}
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderTips(Tips)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={2}>
                        <CustomInputField
                          inputValue={inputCt}
                          setInputValue={setInputCt}
                          label={"Ct"}
                        />
                      </Grid>
                      <Grid item xs={10} md={10}>
                        <CustomInputField
                          inputValue={inputSearchTerm}
                          setInputValue={setInputSearchTerm}
                          label={"Search for ..."}
                        />
                      </Grid>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexWrap="wrap"
                        mt={3}
                        ml={3}
                      >
                        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                          {renderTips(Tips2)}
                        </MDBox>
                      </MDBox>
                      <Grid item xs={2} md={2}>
                        <Button
                          variant="contained"
                          color="white"
                          onClick={handleOpenEditDialog}
                          fullWidth
                        >
                          Generate domain
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={inputRedirectUrl}
                          setInputValue={setInputRedirectUrl}
                          label={"url (format example: https://www.gooogle.com)"}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={urlView}
                          setInputValue={() => {}}
                          label={"URL View"}
                          required={false}
                          readOnly={true}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <GeneratedDomainsTAble />
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Edit Dialog */}
      <DialogFormGenerateDaDomain
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleCloseEditDialog}
        inputSearchTerm={inputSearchTerm}
        setInputSearchTerm={setInputSearchTerm}
        API_URL={API_URL}
        setInputRedirectUrl={setInputRedirectUrl}
        setResponseMessage={setResponseMessage}
        categoriesAll={categoriesAll}
      />
    </DashboardLayout>
  );
}

export default GenerateLink;
