import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const OptionsCheckboxList = ({ onOptionsChange, typesAll, columns = 1 }) => {
  // Состояние для выбранных опций, инициализация с первым элементом по умолчанию
  const [selectedOptions, setSelectedOptions] = useState(() =>
    typesAll.reduce((acc, type, index) => {
      acc[type] = index === 0; // Только первый элемент будет выбран по умолчанию
      return acc;
    }, {})
  );

  // Следим за изменениями в selectedOptions и вызываем onOptionsChange
  useEffect(() => {
    const selectedOptionsArray = Object.keys(selectedOptions).filter(
      (option) => selectedOptions[option]
    );
    console.log("Selected options:", selectedOptionsArray); // Лог для проверки
    onOptionsChange(selectedOptionsArray);
  }, [selectedOptions]); // Убрал onOptionsChange из зависимостей

  // Обработчик изменения состояния чекбокса
  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) => {
      return { ...prev, [option]: !prev[option] };
    });
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "20px" }}>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          {typesAll.map((type, index) => (
            <Grid
              item
              xs={12 / columns} // Делим пространство в зависимости от количества колонок
              key={type}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Checkbox
                    checked={selectedOptions[type]}
                    onChange={() => handleCheckboxChange(type)}
                    inputProps={{ "aria-label": `checkbox for option ${type}` }}
                  />
                </Grid>
                <Grid item>
                  <Typography fontSize={"0.875rem"}>{type}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

OptionsCheckboxList.propTypes = {
  onOptionsChange: PropTypes.func.isRequired,
  typesAll: PropTypes.array.isRequired,
  columns: PropTypes.number, // Новое свойство для указания количества колонок
};

export default OptionsCheckboxList;
