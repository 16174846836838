export function getCurrentTimeDifference() {
  const estoniaTime = new Date().toLocaleString("en-US", { timeZone: "Europe/Tallinn" });
  const laTime = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" });

  // Разница во времени в часах
  const estoniaDate = new Date(estoniaTime);
  const laDate = new Date(laTime);
  const timeDifference = (estoniaDate - laDate) / (1000 * 60 * 60);
  return Math.round(timeDifference);
}

export function getUtcTimeDifference() {
  const localTime = new Date().toLocaleString("en-US", { timeZoneName: "short" });
  const localDate = new Date(localTime);

  // Время UTC
  const utcDate = new Date(localDate.toLocaleString("en-US", { timeZone: "UTC" }));

  // Разница во времени в часах
  const timeDifference = (localDate - utcDate) / (1000 * 60 * 60); // Разница в часах
  return Math.round(timeDifference); // Округляем разницу до целого числа
}
