import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";
import "react-toastify/dist/ReactToastify.css";
import MDTypography from "../../../components/MDTypography";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";

const scrollStyle = {
  maxHeight: "200px",
  maxWidth: "200px",
  overflowY: "auto",
};

const tableTips = [
  "This table is used to display campaigns that are scheduled for launch.",
  "If the table is empty, it means there are no upcoming campaigns.",
];

const tableTips2 = [
  "This table is used to display adsets that are scheduled for launch.",
  "If the table is empty, it means there are no upcoming adsets.",
];

function CampaignsUnderRuleQueue() {
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "update_time", accessor: "update_time", width: "10%" },
      { Header: "activate_time", accessor: "activate_time", width: "10%" },
      { Header: "activate_provider_time", accessor: "activate_provider_time", width: "10%" },
      {
        Header: "campaign Name",
        accessor: "campaign_name",
        width: "10%",
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => <div style={scrollStyle}>{value}</div>,
      },
      { Header: "camp_id", accessor: "camp_id", width: "10%" },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/campaigns_under_rule_queue/"
        );
        const rows = response.data.map((rule) => ({
          update_time: rule.update_time,
          campaign_name: rule.campaign_name,
          camp_id: rule.campaign_id,
          activate_time: rule.activate_time,
          activate_provider_time: rule.activate_provider_time,
        }));
        setTableData((prevState) => ({ ...prevState, rows }));
        setFilteredData(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(
      tableData.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableData.rows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mb={3} display="flex" alignItems="center">
              <img
                src="https://www.svgrepo.com/show/530159/time.svg"
                alt="Image"
                style={{ width: "50px", height: "50px" }}
              />
              <MDTypography variant="h5">&nbsp;&nbsp;Scheduled campaigns</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(tableTips)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData} canSearch entriesPerPage={false} />
            </Paper>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CampaignsUnderRuleQueue;
