import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import CustomInputFieldMultiline from "../../CUSTOM_COMPONENTS/custom_input_field_multiline";
import CustomInputField from "../../CUSTOM_COMPONENTS/custom_input_field";
import MDBox from "../../components/MDBox";
import { renderTips } from "../../CUSTOM_COMPONENTS/render_TIPS";
import CustomDropdown from "../../CUSTOM_COMPONENTS/custom_dropdown";
import { geoAll } from "../CAMPAIGNS_ACTIONS/constants";
import CustomDropdownInput from "../../CUSTOM_COMPONENTS/custom_dropdown_input";

const Tips = [
  "Leave PIXEL field blank to use default value (1417082162390960)",
  "For keywords, use Enter (line break) as a separator. This field is optional and can be left empty.",
];

const Tips2 = [
  "NB! DO NOT WRITE  BRANDS IN CATEGORY NAME!!! STRICTLY FORBIDDEN. If you want to create category i.e. for iPhones - create genearal category name like Cell Phones. Iphones you can add to the key in the link itself.",
];

const Tips3 = [
  "Дата - Источник - Страна - Категория - Имя",
  "24.11.28 - FB - DE - Childcare in Hospital Jobs - Artem",
];

const DialogFormGenerateDaDomain = ({
  openEditDialog,
  handleEditDialogClose,
  inputSearchTerm,
  setInputSearchTerm,
  API_URL,
  setInputRedirectUrl,
  setResponseMessage,
  inputCt,
  setInputCt,
  categoriesAll,
}) => {
  const userEmail = localStorage.getItem("userEmail");
  const [inputKWLIST, setInputKWLIST] = useState("");
  const [inputCampaignName, setInputCampaignName] = useState(null);
  const [inputPixel, setInputPixel] = useState(null);
  const [selectedGeo, setSelectedGeo] = useState("");

  const SaveButton = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
      if (!userEmail || !inputSearchTerm || !inputCampaignName || !selectedGeo) {
        toast.error("Please fill in all required fields.");
        return;
      }
      const requestData = {
        campaign_name: inputCampaignName,
        category: inputSearchTerm,
        serp_pixel: inputPixel && inputPixel.trim() !== "" ? inputPixel : "1417082162390960",
        geo: selectedGeo,
        keywords:
          inputKWLIST && inputKWLIST.trim() !== ""
            ? inputKWLIST
                .split("\n")
                .map((keyword) => keyword.trim())
                .filter((keyword) => keyword !== "")
            : [],
        user_email: userEmail,
      };

      setIsLoading(true);

      try {
        const response = await axios.post(`${API_URL}/generate_DA_domain/`, requestData);
        let responseData;
        if (response.data.includes("https://") && inputCt) {
          responseData = `${response.data}?ad_name={{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&ad_id={{ad.id}}&src=fbk&utm_source=facebook&utm_medium=paid&tg1={{adset.id}}&tg2={{ad.id}}&tg3={{campaign.id}}&tg4={{campaign.name}}&tg5={{placement}}&tg6={{adset.name}}&tg7={{ad.id}}&tg8={{site_source_name}}&ct=${inputCt}&atxt=Search for ${inputSearchTerm}`;
        } else {
          responseData = response.data;
        }
        setInputRedirectUrl(responseData);
        setResponseMessage(JSON.stringify(responseData, null, 2));
      } catch (error) {
        toast.error(
          "Error generating domain: " + (error.response?.data?.message || "Unknown error")
        );
      } finally {
        setIsLoading(false);
        handleEditDialogClose();
      }
    };

    return (
      <Button onClick={handleSubmit} startIcon={<SaveIcon />} disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : "Save"}
      </Button>
    );
  };

  return (
    <Dialog
      open={openEditDialog}
      onClose={handleEditDialogClose}
      fullWidth
      PaperProps={{
        style: {
          width: "500px",
          height: "700px",
        },
      }}
    >
      <Grid item xs={12} md={12}>
        <MDBox mt={3} ml={2} display="flex" alignItems="center">
          <img
            src="https://www.svgrepo.com/show/440504/moon-new-moon.svg"
            alt="Image"
            style={{ width: "35px", height: "35px" }}
          />
          <DialogTitle variant="h1">Generate DA domain</DialogTitle>
        </MDBox>
      </Grid>
      <DialogContent style={{ overflow: "auto" }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mb={3}
        >
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderTips(Tips2, "red")}
          </MDBox>
        </MDBox>
        <Grid item xs={12} md={4}>
          <CustomDropdownInput
            selectedValue={inputSearchTerm}
            setSelectedValue={setInputSearchTerm}
            options={categoriesAll}
            label={"Category name"}
            required={true}
          />
        </Grid>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt={3}
        >
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderTips(Tips3)}
          </MDBox>
        </MDBox>
        <Grid item xs={12} md={12}>
          <CustomInputField
            inputValue={inputCampaignName}
            setInputValue={setInputCampaignName}
            label={"Campaign name"}
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomDropdownInput
            selectedValue={selectedGeo}
            setSelectedValue={setSelectedGeo}
            options={geoAll}
            label={"FB - Geo"}
            required={true}
          />
        </Grid>
        {inputCt !== undefined && setInputCt && (
          <Grid item xs={12} md={10}>
            <CustomInputField
              inputValue={inputCt}
              setInputValue={setInputCt}
              label={"CT"}
              required={true}
            />
          </Grid>
        )}
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt={5}
          mb={3}
        >
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderTips(Tips)}
          </MDBox>
        </MDBox>
        <Grid item xs={12} md={10}>
          <CustomInputField inputValue={inputPixel} setInputValue={setInputPixel} label={"Pixel"} />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomInputFieldMultiline
            inputValue={inputKWLIST}
            setInputValue={setInputKWLIST}
            label={"Keywords"}
            placeholder={"Keyword1\nKeyword2\nKeyword3"}
            multiline
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <SaveButton />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

DialogFormGenerateDaDomain.propTypes = {
  openEditDialog: PropTypes.bool.isRequired,
  handleEditDialogClose: PropTypes.func.isRequired,
  inputSearchTerm: PropTypes.any.isRequired,
  setInputSearchTerm: PropTypes.any.isRequired,
  setInputRedirectUrl: PropTypes.any.isRequired,
  setResponseMessage: PropTypes.any.isRequired,
  API_URL: PropTypes.string.isRequired,
  inputCt: PropTypes.any,
  setInputCt: PropTypes.any,
  categoriesAll: PropTypes.any,
};

export default DialogFormGenerateDaDomain;
