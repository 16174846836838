import React, { useEffect } from "react";
import { Box, FormControl, TextField, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

const CustomInputFieldMultiline = ({
  inputValue,
  setInputValue,
  label,
  multiline,
  required,
  placeholder,
}) => {
  const [touched, setTouched] = React.useState(false);

  useEffect(() => {
    if (required) {
      setTouched(true);
    }
  }, [required]);

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb={1}
      sx={{
        gap: 2,
        width: "100%",
      }}
    >
      <FormControl
        variant="standard"
        sx={{ width: "100%" }}
        required={required}
        error={required && touched && !inputValue}
      >
        <TextField
          value={inputValue}
          label={label}
          placeholder={placeholder}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleBlur}
          multiline={multiline}
          minRows={3} // Начинать с 3 строк
          maxRows={20} // Максимум 10 строк, после этого появляется прокрутка
          sx={{
            width: "100%",
            "& .MuiInputBase-root": {
              resize: "vertical", // Позволяет пользователю вручную растягивать поле
              overflow: "auto", // Добавляет прокрутку при необходимости
              alignItems: "flex-start", // Текст начинается сверху
              padding: "10px", // Добавить отступы
            },
          }}
        />
        {required && touched && !inputValue && (
          <FormHelperText>Please enter {label.toLowerCase()}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

CustomInputFieldMultiline.propTypes = {
  inputValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  setInputValue: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
};

CustomInputFieldMultiline.defaultProps = {
  multiline: true,
  placeholder: "",
};

export default CustomInputFieldMultiline;
