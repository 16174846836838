import { toast } from "react-toastify";

export const fetchCategories = async () => {
  try {
    const response = await fetch(
      "https://dashboard-1049948018753.us-central1.run.app/api/get_domains_constants/"
    );
    if (!response.ok) {
      toast.error("Failed to fetch categories");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};
