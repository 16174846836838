import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import React, { useEffect, useRef } from "react";

function DataTableBodyCell1({
  noBorder,
  align,
  children,
  maxHeight = "700px",
  height,
  isUrl,
  urlParam,
  rowData, // Добавляем параметр для передачи данных строки
  ...rest
}) {
  let displayText = children;

  if (isUrl && typeof children === "object" && children.props?.cell?.value) {
    try {
      const url = new URL(children.props.cell.value);
      const id = url.searchParams.get(urlParam);
      displayText = id ? id : children.props.cell.value;
    } catch (e) {
      console.error("Invalid URL:", children.props.cell.value, e);
      displayText = children.props.cell.value;
    }
  }

  const content = isUrl ? (
    <a
      href={children.props?.cell?.value}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "blue", textDecoration: "underline" }}
    >
      {displayText}
    </a>
  ) : (
    children
  );

  const cellRef = useRef(null);

  useEffect(() => {
    const cells = document.querySelectorAll("tr");
    cells.forEach((row) => {
      const rowCells = row.querySelectorAll("td");
      const maxHeight = Math.max(...Array.from(rowCells).map((cell) => cell.offsetHeight));
      rowCells.forEach((cell) => {
        cell.style.height = `${maxHeight}px`;
      });
    });
  }, [content]);

  // Определяем цвет фона строки в зависимости от значения в колонке `achtung`
  const rowBackgroundColor = rowData?.achtung ? "rgba(255,0,0,0.16)" : "rgba(245,243,243,0.54)";

  return (
    <MDBox
      component="td"
      {...rest}
      textAlign={align}
      ref={cellRef}
      sx={({ palette: { light } }) => ({
        fontSize: "0.875rem",
        border: noBorder ? "none" : "none",
        padding: "0",
        backgroundColor: "transparent",
        borderRadius: "8px",
        margin: "5px",
        marginTop: "10px",
      })}
    >
      <MDBox
        sx={{
          maxHeight: "inherit",
          height: "100%",
          overflowY: "auto",
          border: noBorder ? "none" : `1px solid lightgray`,
          borderRadius: "8px",
          padding: "12px 16px",
          backgroundColor: rowBackgroundColor, // Используем динамически определенный цвет фона
        }}
      >
        {content}
      </MDBox>
    </MDBox>
  );
}

DataTableBodyCell1.defaultProps = {
  noBorder: false,
  align: "left",
  maxHeight: "700px",
  height: "auto",
  isUrl: false,
  urlParam: "",
  rowData: {}, // Устанавливаем значение по умолчанию
};

DataTableBodyCell1.propTypes = {
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.string,
  height: PropTypes.string,
  isUrl: PropTypes.bool,
  urlParam: PropTypes.string,
  rowData: PropTypes.object, // Новый PropType для данных строки
};

export default DataTableBodyCell1;
