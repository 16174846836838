import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MDInput from "../../../components/MDInput";
import Box from "@mui/material/Box";
import FilterCard from "../filter_card_name_status";
import FilterByColumnNameMinMax from "../filter_by_column_tsk";
import FilterByColumnNameSearch from "CUSTOM_COMPONENTS/search_by_column_name";
import DateRangeFilter2 from "../date_filter_2";
import MUISelect from "@mui/material/Select";
import MDTypography from "../../../components/MDTypography";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";

// Стиль для добавления полосы прокрутки
const scrollStyle = {
  maxHeight: "auto",
  minWidth: "20vh",
  maxWidth: "50vh",
  overflowY: "auto",
};

const Tips = [
  "By default, the data is sorted first by the 'started_on' column, then by the 'parse_date' column.",
  "When using the date filter, you don't need to set an end date if it's not defined.",
  "Similarly, you don't need to set a start date if it's not defined.",
  "This system works with all filters that have two values, like start/min and end/max values.",
];

function ParsedFbLinks() {
  // убрать эту шестеренку
  useEffect(() => {
    const element = document.querySelector(".MuiBox-root.css-fxid9l");
    if (element) {
      element.remove();
    }
  }, []);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState({
    columns: [
      {
        Header: "parse_date",
        accessor: "parse_date",
      },
      {
        Header: "fb_library_id",
        accessor: "fb_library_id",
        isUrl: true,
        urlParam: "id",
      },
      { Header: "started_on", accessor: "started_on" },
      { Header: "days_running", accessor: "days_running" },
      { Header: "avg_daily", accessor: "avg_daily" },
      { Header: "country_reach", accessor: "country_reach" },
      { Header: "beneficiary", accessor: "beneficiary" },
      { Header: "keywords", accessor: "keywords" },
      { Header: "payer", accessor: "payer" },
      {
        Header: "search_url",
        accessor: "search_url",
        height: "150px",
      },
      {
        Header: "parse_url",
        accessor: "parse_url",
        height: "200px",
        isUrl: true,
        urlParam: "view_all_page_id",
      },
      { Header: "page_whitelist", accessor: "page_whitelist" },
      { Header: "shows", accessor: "shows" },
      { Header: "amount", accessor: "amount" },
      { Header: "Artem_Y", accessor: "Artem_Y" },
      { Header: "Vlad_A", accessor: "Vlad_A" },
      { Header: "Alex_N", accessor: "Alex_N" },
      { Header: "Andrei_L", accessor: "Andrei_L" },
      { Header: "Marina_C", accessor: "Marina_C" },
      { Header: "Comments", accessor: "Comments" },
      {
        Header: "Artem_Y_Campaign_IDs",
        accessor: "Artem_Y_Campaign_IDs",
        height: "200px",
      },
      {
        Header: "Vlad_A_Campaign_IDs",
        accessor: "Vlad_A_Campaign_IDs",
        height: "200px",
      },
      {
        Header: "Alex_N_Campaign_IDs",
        accessor: "Alex_N_Campaign_IDs",
        height: "200px",
      },
      {
        Header: "Andrei_L_Campaign_IDs",
        accessor: "Andrei_L_Campaign_IDs",
        height: "200px",
      },
      {
        Header: "Marina_C_Campaign_IDs",
        accessor: "Marina_C_Campaign_IDs",
        height: "200px",
      },
      { Header: "EDIT", accessor: "edit" },
    ],
    rows: [],
  });

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tempDaysRunning, setTempDaysRunning] = useState("");
  const statuses = ["Launched", "Preparing", "Not used", "Whitelisted", "Favorite", "Seen", "None"];
  const statuses_page = ["Blacklisted", "Partners", "Whitelisted", "None"];
  const md_names = ["Artem_Y", "Vlad_A", "Alex_N", "Andrei_L", "Marina_C"];
  const userEmail = localStorage.getItem("userEmail");

  const [tempComments, setTempComments] = useState("");
  const [tempArtem_Y_Campaign_IDs, setTempArtem_Y_Campaign_IDs] = useState("");
  const [tempVlad_A_Campaign_IDs, setTempVlad_A_Campaign_IDs] = useState("");
  const [tempAlex_N_Campaign_IDs, setTempAlex_N_Campaign_IDs] = useState("");
  const [tempAndrei_L_Campaign_IDs, setTempAndrei_L_Campaign_IDs] = useState("");
  const [tempMarina_C_Campaign_IDs, setTempMarina_C_Campaign_IDs] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const [selectedColumn, setSelectedColumn] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [startDate, setStartDate] = useState("2020-01-01");
  const [endDate, setEndDate] = useState("2100-01-01");
  const [startParseDate, setStartParseDate] = useState("2020-01-01T00:00:00");
  const [endParseDate, setEndParseDate] = useState("2100-01-01T23:59:59");

  const [selectedColumnAmount, setSelectedColumnAmount] = useState("amount");
  const [minValueAmount, setMinValueAmount] = useState("");
  const [maxValueAmount, setMaxValueAmount] = useState("");
  const [selectedColumnDR, setSelectedColumnDR] = useState("days_running");
  const [minValueDR, setMinValueDR] = useState("");
  const [maxValueDR, setMaxValueDR] = useState("");
  const [selectedColumnShows, setSelectedColumnShows] = useState("shows");
  const [minValueShows, setMinValueShows] = useState("");
  const [maxValueShows, setMaxValueShows] = useState("");
  const [selectedColumnAVG, setSelectedColumnAVG] = useState("avg_daily");
  const [minValueAVG, setMinValueAVG] = useState("");
  const [maxValueAVG, setMaxValueAVG] = useState("");

  const [selectedColumnFBID, setSelectedColumnFBID] = useState("fb_library_id");
  const [searchTermFBID, setSearchTermFBID] = useState("");
  const [selectedColumnCountryReach, setSelectedColumnCountryReach] = useState("country_reach");
  const [searchTermCountryReach, setSearchTermCountryReach] = useState("");
  const [selectedColumnPayer, setSelectedColumnPayerD] = useState("payer");
  const [searchTermPayer, setSearchTermPayer] = useState("");
  const [selectedColumnBenef, setSelectedColumnBenef] = useState("beneficiary");
  const [searchTermBenef, setSearchTermBenef] = useState("");
  const [selectedColumnKeywords, setSelectedColumnKeywords] = useState("keywords");
  const [searchTermKeywords, setSearchTermKeywords] = useState("");

  const handleStatusChange = (row, column) => async (event) => {
    const updatedValue = event.target.value;

    const updatedData = {
      parse_date: row.parse_date,
      fb_library_id: row.fb_library_id,
      Artem_Y: row.Artem_Y,
      Vlad_A: row.Vlad_A,
      Alex_N: row.Alex_N,
      Andrei_L: row.Andrei_L,
      Marina_C: row.Marina_C,
      page_whitelist: row.page_whitelist,
      Comments: row.Comments,
      Artem_Y_Campaign_IDs: row.Artem_Y_Campaign_IDs,
      Vlad_A_Campaign_IDs: row.Vlad_A_Campaign_IDs,
      Alex_N_Campaign_IDs: row.Alex_N_Campaign_IDs,
      Andrei_L_Campaign_IDs: row.Andrei_L_Campaign_IDs,
      Marina_C_Campaign_IDs: row.Marina_C_Campaign_IDs,
      days_running: row.days_running,
      [column]: updatedValue,
      user_email: userEmail,
    };

    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((r) =>
        r.fb_library_id === row.fb_library_id && r.parse_date === row.parse_date
          ? { ...r, [column]: updatedValue }
          : r
      ),
    }));

    try {
      await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/parsed_fb_links_update/",
        updatedData
      );
      toast.success("Selected categories updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error.response.data);
      toast.error("Failed to update selected categories!");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/get_parsed_fb_links/"
        );
        console.log("Количество строк до фильтрации:", response.data.length);
        setTableData((prevState) => ({ ...prevState, rows: response.data }));
        setFilteredData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const isDateInRange = (dateStr, startDate, endDate) => {
      if (!dateStr || dateStr === "NaT") return true;

      const date = new Date(dateStr);
      if (isNaN(date.getTime())) return false;

      const start = startDate ? new Date(startDate) : new Date("2001-01-01T00:00:00");
      const end = endDate ? new Date(endDate) : new Date("2100-01-01T23:59:59");

      return date >= start && date <= end;
    };

    let filteredRows = tableData.rows;

    // Фильтрация по статусу
    filteredRows = filteredRows.filter((row) => {
      if (selectedStatus && selectedColumn !== "All") {
        return row[selectedColumn] === selectedStatus;
      }
      return true;
    });
    console.log("После фильтрации по статусу:", filteredRows.length);

    // Фильтрация по min/max amount
    filteredRows = filteredRows.filter((row) => {
      const searchMatch = Object.values(row).some((val) =>
        String(val).toLowerCase().includes(search.toLowerCase())
      );

      let columnFilterMatch = true;
      if (selectedColumnAmount && minValueAmount && maxValueAmount) {
        const value = parseFloat(row[selectedColumnAmount]);
        columnFilterMatch =
          value >= parseFloat(minValueAmount) && value <= parseFloat(maxValueAmount);
      }

      return searchMatch && columnFilterMatch;
    });
    console.log("После фильтрации по суммам:", filteredRows.length);

    // Фильтрация по days_running
    filteredRows = filteredRows.filter((row) => {
      const searchMatch = Object.values(row).some((val) =>
        String(val).toLowerCase().includes(search.toLowerCase())
      );

      let columnFilterMatch = true;
      if (selectedColumnDR && minValueDR && maxValueDR) {
        const value = parseFloat(row[selectedColumnDR]);
        columnFilterMatch = value >= parseFloat(minValueDR) && value <= parseFloat(maxValueDR);
      }

      return searchMatch && columnFilterMatch;
    });
    console.log("После фильтрации по дням:", filteredRows.length);

    // Фильтрация по shows
    filteredRows = filteredRows.filter((row) => {
      const searchMatch = Object.values(row).some((val) =>
        String(val).toLowerCase().includes(search.toLowerCase())
      );

      let columnFilterMatch = true;
      if (selectedColumnShows && minValueShows && maxValueShows) {
        const value = parseFloat(row[selectedColumnShows]);
        columnFilterMatch =
          value >= parseFloat(minValueShows) && value <= parseFloat(maxValueShows);
      }

      return searchMatch && columnFilterMatch;
    });
    console.log("После фильтрации по показам:", filteredRows.length);

    // Фильтрация по avg_daily
    filteredRows = filteredRows.filter((row) => {
      const searchMatch = Object.values(row).some((val) =>
        String(val).toLowerCase().includes(search.toLowerCase())
      );

      let columnFilterMatch = true;
      if (selectedColumnAVG && minValueAVG && maxValueAVG) {
        const value = parseFloat(row[selectedColumnAVG]);
        columnFilterMatch = value >= parseFloat(minValueAVG) && value <= parseFloat(maxValueAVG);
      }

      return searchMatch && columnFilterMatch;
    });
    console.log("После фильтрации по среднему за день:", filteredRows.length);

    // // Фильтрация по started_on
    filteredRows = filteredRows.filter((row) => {
      return isDateInRange(row.started_on, startDate, endDate);
    });
    console.log("После фильтрации по дате начала:", filteredRows.length);

    // Фильтрация по parse_date
    filteredRows = filteredRows.filter((row) => {
      return isDateInRange(row.parse_date, startParseDate, endParseDate);
    });
    console.log("После фильтрации по дате парсинга:", filteredRows.length);

    // Фильтрация по fb_library_id
    filteredRows = filteredRows.filter((row) => {
      if (!searchTermFBID) return true; // No filter if searchTerm is empty
      return String(row[selectedColumnFBID] || "")
        .toLowerCase()
        .includes(searchTermFBID.toLowerCase());
    });
    console.log("После фильтрации по FB ID:", filteredRows.length);

    // Фильтрация по country_reach
    filteredRows = filteredRows.filter((row) => {
      if (!searchTermCountryReach) return true; // No filter if searchTerm is empty
      return String(row[selectedColumnCountryReach] || "")
        .toLowerCase()
        .includes(searchTermCountryReach.toLowerCase());
    });
    console.log("После фильтрации по country_reach:", filteredRows.length);

    // Фильтрация по payer
    filteredRows = filteredRows.filter((row) => {
      if (!searchTermPayer) return true; // No filter if searchTerm is empty
      return String(row[selectedColumnPayer] || "")
        .toLowerCase()
        .includes(searchTermPayer.toLowerCase());
    });
    console.log("После фильтрации по плательщику:", filteredRows.length);

    // Фильтрация по beneficiary
    filteredRows = filteredRows.filter((row) => {
      if (!searchTermBenef) return true; // No filter if searchTerm is empty
      return String(row[selectedColumnBenef] || "")
        .toLowerCase()
        .includes(searchTermBenef.toLowerCase());
    });
    console.log("После фильтрации по бенефициару:", filteredRows.length);

    // Фильтрация по keywords
    filteredRows = filteredRows.filter((row) => {
      if (!searchTermKeywords) return true; // No filter if searchTerm is empty
      return String(row[selectedColumnKeywords] || "")
        .toLowerCase()
        .includes(searchTermKeywords.toLowerCase());
    });
    console.log("После фильтрации по ключевым словам:", filteredRows.length);

    setFilteredData(filteredRows);
  }, [
    search,
    tableData.rows,
    selectedStatus,
    selectedColumn,
    startDate,
    endDate,
    startParseDate,
    endParseDate,
    minValueAmount,
    maxValueAmount,
    selectedColumnAmount,
    minValueDR,
    maxValueDR,
    selectedColumnDR,
    minValueAVG,
    maxValueAVG,
    selectedColumnAVG,
    minValueShows,
    maxValueShows,
    selectedColumnShows,
    searchTermFBID,
    searchTermCountryReach,
    searchTermPayer,
    searchTermBenef,
    searchTermKeywords,
  ]);

  const renderSelect = (row, column, isPageWhitelist = false) => {
    const currentStatuses = isPageWhitelist ? statuses_page : statuses;

    const borderColor =
      row[column] === "Launched"
        ? "green"
        : row[column] === "Preparing"
        ? "yellow"
        : row[column] === "Not used"
        ? "red"
        : row[column] === "Whitelisted"
        ? "gray"
        : row[column] === "Blacklisted"
        ? "black"
        : row[column] === "Partners"
        ? "orange"
        : row[column] === "Favorite"
        ? "pink"
        : row[column] === "Seen"
        ? "blue"
        : "purple";

    const backgroundColor =
      borderColor === "green"
        ? "rgba(5,250,5,0.2)"
        : borderColor === "yellow"
        ? "rgba(248,248,2,0.2)"
        : borderColor === "red"
        ? "rgba(255, 0, 0, 0.2)"
        : borderColor === "blue"
        ? "rgba(1,214,239,0.2)"
        : borderColor === "gray"
        ? "rgba(17,255,0,0.38)"
        : borderColor === "black"
        ? "rgba(189,0,255,0.24)"
        : borderColor === "orange"
        ? "rgba(255,165,0,0.2)"
        : borderColor === "pink"
        ? "rgba(255,192,203,0.2)"
        : "rgba(86,0,255,0.2)";

    const handleSelectChange = (event) => {
      const selectedStatus = event.target.value;
      const userEmail = localStorage.getItem("userEmail");
      const role = localStorage.getItem("userRole");

      const emailToColumn = {
        "Artemyusi@gmail.com": "Artem_Y_Campaign_IDs",
        "Vlad0986613794@gmail.com": "Vlad_A_Campaign_IDs",
        "nuzhnyias@gmail.com": "Alex_N_Campaign_IDs",
        "andreylipovskiy0@gmail.com": "Andrei_L_Campaign_IDs",
        "mcerneva2@gmail.com": "Marina_C_Campaign_IDs",
      };

      const campaignColumn = emailToColumn[userEmail];

      if (selectedStatus === "Launched" && !isPageWhitelist) {
        if (role === "admin") {
          alert("You have admin access and can change statuses without Campaign IDs.");
          handleStatusChange(row, column)(event);
        } else if (!row[campaignColumn] || row[campaignColumn].length === 0) {
          alert("Please provide Campaign IDs to set the status to 'Launched'.");
        } else {
          handleStatusChange(row, column)(event);
        }
      } else {
        handleStatusChange(row, column)(event);
      }
    };

    return (
      <MUISelect
        value={row[column]}
        onChange={handleSelectChange}
        sx={{
          color: borderColor,
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
          backgroundColor: backgroundColor,
          "&:hover": {
            backgroundColor: backgroundColor,
          },
        }}
        variant="outlined"
      >
        {currentStatuses.map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </MUISelect>
    );
  };

  const handleSave = async () => {
    if (!selectedRow) return;

    const updatedData = {
      fb_library_id: selectedRow.fb_library_id,
      parse_date: selectedRow.parse_date,
      Comments: tempComments,
      Artem_Y_Campaign_IDs: tempArtem_Y_Campaign_IDs,
      Vlad_A_Campaign_IDs: tempVlad_A_Campaign_IDs,
      Alex_N_Campaign_IDs: tempAlex_N_Campaign_IDs,
      Andrei_L_Campaign_IDs: tempAndrei_L_Campaign_IDs,
      Marina_C_Campaign_IDs: tempMarina_C_Campaign_IDs,
      days_running: tempDaysRunning,
      user_email: userEmail,
    };

    try {
      await axios.post(
        "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/parsed_fb_links_update/",
        updatedData
      );

      const updatedRows = tableData.rows.map((r) =>
        r.fb_library_id === selectedRow.fb_library_id && r.parse_date === selectedRow.parse_date
          ? {
              ...r,
              Comments: tempComments,
              Artem_Y_Campaign_IDs: tempArtem_Y_Campaign_IDs,
              Vlad_A_Campaign_IDs: tempVlad_A_Campaign_IDs,
              Alex_N_Campaign_IDs: tempAlex_N_Campaign_IDs,
              Andrei_L_Campaign_IDs: tempAndrei_L_Campaign_IDs,
              Marina_C_Campaign_IDs: tempMarina_C_Campaign_IDs,
              days_running: tempDaysRunning,
            }
          : r
      );

      setTableData((prevState) => ({
        ...prevState,
        rows: updatedRows,
      }));

      toast.success("Comments and Campaign IDs updated successfully!");
    } catch (error) {
      console.error("Error updating data:", error.response.data);
      toast.error("Failed to update Comments and Campaign IDs!");
    }

    handleEditDialogClose();
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
    setTempComments(row.Comments);
    setTempArtem_Y_Campaign_IDs(row.Artem_Y_Campaign_IDs);
    setTempVlad_A_Campaign_IDs(row.Vlad_A_Campaign_IDs);
    setTempAlex_N_Campaign_IDs(row.Alex_N_Campaign_IDs);
    setTempAndrei_L_Campaign_IDs(row.Andrei_L_Campaign_IDs);
    setTempMarina_C_Campaign_IDs(row.Marina_C_Campaign_IDs);
    setTempDaysRunning(row.days_running);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
    setTempComments("");
    setTempArtem_Y_Campaign_IDs("");
    setTempVlad_A_Campaign_IDs("");
    setTempAlex_N_Campaign_IDs("");
    setTempAndrei_L_Campaign_IDs("");
    setTempMarina_C_Campaign_IDs("");
    setTempDaysRunning("");
  };

  const memoizedRows = useMemo(
    () =>
      filteredData.map((row) => ({
        ...row,
        Artem_Y: renderSelect(row, "Artem_Y"),
        Vlad_A: renderSelect(row, "Vlad_A"),
        Alex_N: renderSelect(row, "Alex_N"),
        Andrei_L: renderSelect(row, "Andrei_L"),
        Marina_C: renderSelect(row, "Marina_C"),
        page_whitelist: renderSelect(row, "page_whitelist", true),
        edit: (
          <IconButton onClick={() => handleEditClick(row)}>
            <EditIcon />
          </IconButton>
        ),
      })),
    [filteredData]
  );

  const handleDateRangeChange = (dateRange) => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  };

  const handleParseDateRangeChange = (dateRange) => {
    setStartParseDate(dateRange.startDate);
    setEndParseDate(dateRange.endDate);
  };

  const handleColumnChangeAmount = (value) => {
    setSelectedColumnAmount(value);
  };

  const handleMinValueAmountChange = (value) => {
    setMinValueAmount(value);
  };

  const handleMaxValueAmountChange = (value) => {
    setMaxValueAmount(value);
  };

  const handleColumnChangeDR = (value) => {
    setSelectedColumnDR(value);
  };

  const handleMinValueDRChange = (value) => {
    setMinValueDR(value);
  };

  const handleMaxValueDRChange = (value) => {
    setMaxValueDR(value);
  };

  const handleColumnChangeShows = (value) => {
    setSelectedColumnShows(value);
  };

  const handleMinValueShowsChange = (value) => {
    setMinValueShows(value);
  };

  const handleMaxValueShowsChange = (value) => {
    setMaxValueShows(value);
  };

  const handleColumnChangeAVG = (value) => {
    setSelectedColumnAVG(value);
  };

  const handleMinValueAVGChange = (value) => {
    setMinValueAVG(value);
  };

  const handleMaxValueAVGChange = (value) => {
    setMaxValueAVG(value);
  };

  const handleSearchTermChangeFBID = (event) => {
    setSearchTermFBID(event.target.value);
  };

  const handleSearchTermChangeCountryReach = (event) => {
    setSearchTermCountryReach(event.target.value);
  };

  const handleSearchTermChangePayer = (event) => {
    setSearchTermPayer(event.target.value);
  };

  const handleSearchTermChangeBenef = (event) => {
    setSearchTermBenef(event.target.value);
  };

  const handleSearchTermChangeKeywords = (event) => {
    setSearchTermKeywords(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid item xs={12}>
          <MDBox pt={3} pb={3} px={2}>
            <Grid item xs={12} lg={12}>
              <MDBox mb={2}>
                <MDTypography variant="h5">Table tips</MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
              >
                <MDBox component="ul" m={0} pl={3.25} mb={3}>
                  {renderTips(Tips)}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2}>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameMinMax
                    onColumnChange={handleColumnChangeAmount}
                    onMinValueChange={handleMinValueAmountChange}
                    onMaxValueChange={handleMaxValueAmountChange}
                    minValue={minValueAmount}
                    maxValue={maxValueAmount}
                    columnName={"amount"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameMinMax
                    onColumnChange={handleColumnChangeDR}
                    onMinValueChange={handleMinValueDRChange}
                    onMaxValueChange={handleMaxValueDRChange}
                    minValue={minValueDR}
                    maxValue={maxValueDR}
                    columnName={"days_running"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameMinMax
                    onColumnChange={handleColumnChangeShows}
                    onMinValueChange={handleMinValueShowsChange}
                    onMaxValueChange={handleMaxValueShowsChange}
                    minValue={minValueShows}
                    maxValue={maxValueShows}
                    columnName={"shows"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameMinMax
                    onColumnChange={handleColumnChangeAVG}
                    onMinValueChange={handleMinValueAVGChange}
                    onMaxValueChange={handleMaxValueAVGChange}
                    minValue={minValueAVG}
                    maxValue={maxValueAVG}
                    columnName={"avg_daily"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameSearch
                    columnName={selectedColumnFBID}
                    searchTerm={searchTermFBID}
                    onSearchTermChange={handleSearchTermChangeFBID}
                    columnLabel={"fb_library_id"}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterCard
                    selectedColumn={selectedColumn}
                    setSelectedColumn={setSelectedColumn}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    statuses={statuses}
                    md_names={md_names}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box display="flex" justifyContent="center" width="100%">
                  <DateRangeFilter2
                    onDateRangeChange={handleDateRangeChange}
                    columnName={"started_on"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box display="flex" justifyContent="center" width="100%">
                  <DateRangeFilter2
                    onDateRangeChange={handleParseDateRangeChange}
                    columnName={"parse_date"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2.5}>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameSearch
                    columnName={selectedColumnCountryReach}
                    searchTerm={searchTermCountryReach}
                    onSearchTermChange={handleSearchTermChangeCountryReach}
                    columnLabel={"country_reach"}
                  />
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameSearch
                    columnName={selectedColumnPayer}
                    searchTerm={searchTermPayer}
                    onSearchTermChange={handleSearchTermChangePayer}
                    columnLabel={"payer"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2.5}>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameSearch
                    columnName={selectedColumnBenef}
                    searchTerm={searchTermBenef}
                    onSearchTermChange={handleSearchTermChangeBenef}
                    columnLabel={"beneficiary"}
                  />
                </Box>
                <Box display="flex" justifyContent="center" width="100%">
                  <FilterByColumnNameSearch
                    columnName={selectedColumnKeywords}
                    searchTerm={searchTermKeywords}
                    onSearchTermChange={handleSearchTermChangeKeywords}
                    columnLabel={"keywords"}
                  />
                </Box>
              </Grid>
            </Grid>
            <DataTable
              table={{ ...tableData, rows: memoizedRows }}
              entriesPerPage={false}
              canSearch
            />
          </MDBox>
        </Grid>
      </MDBox>

      <Dialog
        open={openEditDialog}
        onClose={handleEditDialogClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: "30%",
            height: "70%",
          },
        }}
      >
        <DialogTitle>Edit Comments & IDs</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <MDInput
            label="Comments"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={tempComments}
            onChange={(e) => setTempComments(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          />
          <MDInput
            label="Artem_Y Campaign IDs"
            value={tempArtem_Y_Campaign_IDs}
            onChange={(e) => setTempArtem_Y_Campaign_IDs(e.target.value)}
            fullWidth
            sx={{ height: "60px" }}
          />
          <MDInput
            label="Vlad_A Campaign IDs"
            value={tempVlad_A_Campaign_IDs}
            onChange={(e) => setTempVlad_A_Campaign_IDs(e.target.value)}
            fullWidth
            sx={{ height: "60px" }}
          />
          <MDInput
            label="Alex_N Campaign IDs"
            value={tempAlex_N_Campaign_IDs}
            onChange={(e) => setTempAlex_N_Campaign_IDs(e.target.value)}
            fullWidth
            sx={{ height: "60px" }}
          />
          <MDInput
            label="Andrei_L Campaign IDs"
            value={tempAndrei_L_Campaign_IDs}
            onChange={(e) => setTempAndrei_L_Campaign_IDs(e.target.value)}
            fullWidth
            sx={{ height: "60px" }}
          />
          <MDInput
            label="Marina_C Campaign IDs"
            value={tempMarina_C_Campaign_IDs}
            onChange={(e) => setTempMarina_C_Campaign_IDs(e.target.value)}
            fullWidth
            sx={{ height: "60px" }}
          />
          <MDInput
            label="Days Running"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={tempDaysRunning}
            onChange={(e) => setTempDaysRunning(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ParsedFbLinks;
