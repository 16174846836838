import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import AccountCheckboxList from "../data_accounts";
import CampaignCheckboxList from "../data_campaigns";
import { Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import MDTypography from "../../../components/MDTypography";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";
import { useFilteredData, useTableDataAdsetLogs } from "../adsets_logs";
import DialogFormStopLoss2 from "./stop_loss_2_dialog";

function StopLoss2() {
  const [tableData1, setTableData1] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "30%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "58%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "10%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" },
    ],
    rows: [],
  });

  const [tableData2, setTableData2] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "30%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "58%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "10%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");

  const [tempValue1, setTempValue1] = useState("");
  const [tempValue2, setTempValue2] = useState("");
  const [tempValue3, setTempValue3] = useState("");
  const [tempAction, setTempAction] = useState("");
  const [tempActionValue, setTempActionValue] = useState("");

  const [tempValue1_USER1, setTempValue1_USER1] = useState("");
  const [tempValue2_USER1, setTempValue2_USER1] = useState("");
  const [tempValue3_USER1, setTempValue3_USER1] = useState("");
  const [tempAction_USER1, setTempAction_USER1] = useState("");
  const [tempActionValue_USER1, setTempActionValue_USER1] = useState("");

  const [tempValue1_USER2, setTempValue1_USER2] = useState("");
  const [tempValue2_USER2, setTempValue2_USER2] = useState("");
  const [tempValue3_USER2, setTempValue3_USER2] = useState("");
  const [tempAction_USER2, setTempAction_USER2] = useState("");
  const [tempActionValue_USER2, setTempActionValue_USER2] = useState("");

  const [tempValue1_USER3, setTempValue1_USER3] = useState("");
  const [tempValue2_USER3, setTempValue2_USER3] = useState("");
  const [tempValue3_USER3, setTempValue3_USER3] = useState("");
  const [tempAction_USER3, setTempAction_USER3] = useState("");
  const [tempActionValue_USER3, setTempActionValue_USER3] = useState("");

  const [tempValue1_USER4, setTempValue1_USER4] = useState("");
  const [tempValue2_USER4, setTempValue2_USER4] = useState("");
  const [tempValue3_USER4, setTempValue3_USER4] = useState("");
  const [tempAction_USER4, setTempAction_USER4] = useState("");
  const [tempActionValue_USER4, setTempActionValue_USER4] = useState("");

  const [tempValue1_USER5, setTempValue1_USER5] = useState("");
  const [tempValue2_USER5, setTempValue2_USER5] = useState("");
  const [tempValue3_USER5, setTempValue3_USER5] = useState("");
  const [tempAction_USER5, setTempAction_USER5] = useState("");
  const [tempActionValue_USER5, setTempActionValue_USER5] = useState("");

  const [tempValue1_USER6, setTempValue1_USER6] = useState("");
  const [tempValue2_USER6, setTempValue2_USER6] = useState("");
  const [tempValue3_USER6, setTempValue3_USER6] = useState("");
  const [tempAction_USER6, setTempAction_USER6] = useState("");
  const [tempActionValue_USER6, setTempActionValue_USER6] = useState("");

  const [firstRuleStatus1, setFirstRuleStatus1] = useState(false);
  const [firstRuleStatus2, setFirstRuleStatus2] = useState(false);

  const tableDataAdsetLogs = useTableDataAdsetLogs("stop_loss_2");
  const filteredDataLogs = useFilteredData(tableDataAdsetLogs, search);

  const API_URL = "https://dashboard-1049948018753.us-central1.run.app";

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const userNames = {
    "Artemyusi@gmail.com": "USER1",
    "Vlad0986613794@gmail.com": "USER2",
    "nuzhnyias@gmail.com": "USER3",
    "andreylipovskiy0@gmail.com": "USER4",
    "mcerneva2@gmail.com": "USER5",
    "Hembarskyi_user@gmail.com": "USER6",
  };

  const budgetROITips = [
    "The symbols < and > exclude the number they compare to, so the boundary is not included.",
    "The symbols <= and >= include the number they compare to, so the boundary is included.",
    "For negative values, specify the dash symbol without spaces(ex. -25).",
  ];
  const ruleTips = [
    "This rules set applies every hour after 6 AM.",
    "This rule set applies to CBO at the campaign level and to ABO at the ad set level.",
    "To disable a rule, turn it off for all accounts or use the toggle.",
    "If you disable the accounts or campaigns listed below, this will apply to all rules in this set.",
    "When you edit a rule, you're only editing it for yourself; it won't affect other people's campaigns.",
    "Campaigns that include matches from the exclusion list will be automatically excluded (TEST_CAMPAIGN, Engagement campaign, COST_CAP, COSTCAP, BID_CAP, BIDCAP, GC-UTC-305, GC-UTC-304, - L0, (S1_), L1",
  ];

  const userName = userNames[userEmail];

  const actionOptions = [
    "только отправить уведомление",
    "остановить кампанию",
    "остановить адсет",
    "понизить бюджет нa ",
    "понизить бюджет до уровня spend",
  ];

  const handleActionChange = (event) => {
    const newValue = event.target.value;
    setSelectedAction(newValue);
    if (userRole === "admin") {
      setTempAction(newValue);
    } else {
      switch (userNames[userEmail]) {
        case "USER1":
          setTempAction_USER1(newValue);
          break;
        case "USER2":
          setTempAction_USER2(newValue);
          break;
        case "USER3":
          setTempAction_USER3(newValue);
          break;
        case "USER4":
          setTempAction_USER4(newValue);
          break;
        case "USER5":
          setTempAction_USER5(newValue);
          break;
        case "USER6":
          setTempAction_USER6(newValue);
          break;
        default:
          console.error("Unknown user");
      }
    }
  };

  const generateCondition = (rule, userRole, userName) => {
    if (userRole === "admin") {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            {rule.condition1}
            {rule.value_1}
            {rule.condition1_1}
            {rule.value2}
            {rule.condition1_1 !== "" ? "%" : "$"} {rule.condition1_2}
            {rule.value3}
            {rule.value3 !== "" ? "$" : ""}
          </div>
        </pre>
      );
    } else {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            {rule.condition1} {rule[`value1_${userName}`]}
            {rule.condition1_1}
            {rule[`value2_${userName}`]}
            {rule.condition1_1 !== "" ? "%" : "$"} {rule.condition1_2}
            {rule[`value3_${userName}`]}
            {rule.value3 !== "" ? "$" : ""}
          </div>
        </pre>
      );
    }
  };

  const generateAction = (rule, userRole, userName) => {
    if (userRole === "admin") {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
              alt="Red Arrow"
              style={{ width: "24px", height: "24px", marginRight: "20px" }}
            />
            {rule.action}
            <span>
              {[
                "только отправить уведомление",
                "остановить кампанию",
                "остановить адсет",
                "понизить бюджет до уровня spend",
              ].includes(rule.action) ? null : (
                <>
                  {rule.action_value}
                  {rule.action_value !== "" ? "%" : ""}
                </>
              )}
            </span>
          </div>
        </pre>
      );
    } else {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
              alt="Red Arrow"
              style={{ width: "24px", height: "24px", marginRight: "20px" }}
            />
            {rule[`action_${userName}`]}
            <span>
              {[
                "только отправить уведомление",
                "остановить кампанию",
                "остановить адсет",
                "понизить бюджет до уровня spend",
              ].includes(rule[`action_${userName}`]) ? null : (
                <>
                  {rule[`action_value_${userName}`]}
                  {rule.action_value !== "" ? "%" : ""}
                </>
              )}
            </span>
          </div>
        </pre>
      );
    }
  };

  const fetchData = async (ruleNamePattern1, ruleNamePattern2) => {
    try {
      const response1 = await axios.post(`${API_URL}/api/rules/rules_stop_loss_2/`, {
        rule_names: ruleNamePattern1,
      });
      const response2 = await axios.post(`${API_URL}/api/rules/rules_stop_loss_2/`, {
        rule_names: ruleNamePattern2,
      });

      // Обработка данных первого запроса
      const rows1 = response1.data.map((rule) => {
        if (rule.rule_name === "roi_check_1") {
          setFirstRuleStatus1(
            userRole === "admin" ? rule.rule_global_status : rule[`rule_global_status_${userName}`]
          );
        }
        const conditionContent = generateCondition(rule, userRole, userName);
        const actionContent = generateAction(rule, userRole, userName);

        return {
          rule_global_status: rule.rule_global_status,
          rule_name: (
            <div>
              {/* Глобальный статус (для админа) */}
              <FormControlLabel
                control={
                  <Switch
                    checked={rule.rule_status}
                    onChange={() => {
                      const currentStatus = rule.rule_status;
                      handleToggle(rule.rule_name, "rule_status", currentStatus);
                    }}
                    disabled={userRole !== "admin"}
                  />
                }
                label={`${rule.rule_name}`}
              />

              {userRole !== "admin" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={rule[`rule_status_${userName}`]}
                      onChange={() => {
                        const currentUserStatus = rule[`rule_status_${userName}`];
                        handleToggle(rule.rule_name, `rule_status_${userName}`, currentUserStatus);
                      }}
                    />
                  }
                  label={`${userNames[userEmail]}`}
                />
              )}
            </div>
          ),
          condition: conditionContent,
          action: actionContent,
          edit: (
            <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
              <EditIcon />
            </IconButton>
          ),
        };
      });

      const rows2 = response2.data.map((rule) => {
        if (rule.rule_name === "rpc_check_1") {
          setFirstRuleStatus2(
            userRole === "admin" ? rule.rule_global_status : rule[`rule_global_status_${userName}`]
          );
        }
        const conditionContent = generateCondition(rule, userRole, userName);
        const actionContent = generateAction(rule, userRole, userName);

        return {
          rule_name: (
            <div>
              {/* Глобальный статус (для админа) */}
              <FormControlLabel
                control={
                  <Switch
                    checked={rule.rule_status}
                    onChange={() => {
                      const currentStatus = rule.rule_status;
                      handleToggle(rule.rule_name, "rule_status", currentStatus);
                    }}
                    disabled={userRole !== "admin"}
                  />
                }
                label={`${rule.rule_name}`}
              />

              {userRole !== "admin" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={rule[`rule_status_${userName}`]}
                      onChange={() => {
                        const currentUserStatus = rule[`rule_status_${userName}`];
                        handleToggle(rule.rule_name, `rule_status_${userName}`, currentUserStatus);
                      }}
                    />
                  }
                  label={`${userNames[userEmail]}`}
                />
              )}
            </div>
          ),
          condition: conditionContent,
          action: actionContent,
          edit: (
            <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
              <EditIcon />
            </IconButton>
          ),
        };
      });

      setTableData1((prevState) => ({ ...prevState, rows: rows1 }));
      setTableData2((prevState) => ({ ...prevState, rows: rows2 }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData("roi", "rpc");
  }, []);

  const handleToggle = useCallback(async (ruleName = "", columnName, currentStatus) => {
    const newStatus = currentStatus === true ? "Inactive" : "Active";
    console.log("Current status/new status:", currentStatus, newStatus);

    setTableData1((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row) =>
        row.rule_name === ruleName
          ? {
              ...row,
              [columnName]: !currentStatus, // Локально обновляем статус
            }
          : row
      ),
    }));
    setTableData2((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row) =>
        row.rule_name === ruleName
          ? {
              ...row,
              [columnName]: !currentStatus, // Локально обновляем статус
            }
          : row
      ),
    }));

    // Send request to update rule status
    const url = `${API_URL}/api/rules_stop_loss_2/update_status/`;

    try {
      console.log("rule_name/columnName/newStatus:", ruleName, columnName, newStatus);
      await axios.post(url, {
        rule_name: ruleName,
        column_name: columnName,
        status: newStatus,
        user_email: userEmail,
      });
      toast.success(`Status for ${columnName} updated to ${newStatus}!`);
      fetchData("roi", "rpc");
    } catch (error) {
      setTableData1((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_name === ruleName ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      setTableData2((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_name === ruleName ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      toast.error(`Failed to update rule status for ID ${ruleName}.`);
    }
  }, []);

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
    if (userRole === "admin") {
      setSelectedAction(row.action);
    } else {
      switch (userNames[userEmail]) {
        case "USER1":
          setSelectedAction(tempAction_USER1 || row.action);
          break;
        case "USER2":
          setSelectedAction(tempAction_USER2 || row.action);
          break;
        case "USER3":
          setSelectedAction(tempAction_USER3 || row.action);
          break;
        case "USER4":
          setSelectedAction(tempAction_USER4 || row.action);
          break;
        case "USER5":
          setSelectedAction(tempAction_USER5 || row.action);
          break;
        case "USER6":
          setSelectedAction(tempAction_USER5 || row.action);
          break;
        default:
          console.error("Unknown user");
      }
    }

    setTempValue1(row.value_1);
    setTempValue2(row.value2);
    setTempValue3(row.value3);
    setTempAction(row.action);

    setTempActionValue(row.action_value);
    setTempValue1_USER1(row.value1_USER1);
    setTempValue2_USER1(row.value2_USER1);
    setTempValue3_USER1(row.value3_USER1);
    setTempAction_USER1(row.action_USER1);
    setTempActionValue_USER1(row.action_value_USER1);

    setTempValue1_USER2(row.value1_USER2);
    setTempValue2_USER2(row.value2_USER2);
    setTempValue3_USER2(row.value3_USER2);
    setTempAction_USER2(row.action_USER2);
    setTempActionValue_USER2(row.action_value_USER2);

    setTempValue1_USER3(row.value1_USER3);
    setTempValue2_USER3(row.value2_USER3);
    setTempValue3_USER3(row.value3_USER3);
    setTempAction_USER3(row.action_USER3);
    setTempActionValue_USER3(row.action_value_USER3);

    setTempValue1_USER4(row.value1_USER4);
    setTempValue2_USER4(row.value2_USER4);
    setTempValue3_USER4(row.value3_USER4);
    setTempAction_USER4(row.action_USER4);
    setTempActionValue_USER4(row.action_value_USER4);

    setTempValue1_USER5(row.value1_USER5);
    setTempValue2_USER5(row.value2_USER5);
    setTempValue3_USER5(row.value3_USER5);
    setTempAction_USER5(row.action_USER5);
    setTempActionValue_USER5(row.action_value_USER5);

    setTempValue1_USER6(row.value1_USER6);
    setTempValue2_USER6(row.value2_USER6);
    setTempValue3_USER6(row.value3_USER6);
    setTempAction_USER6(row.action_USER6);
    setTempActionValue_USER6(row.action_value_USER6);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);

    setTempValue1("");
    setTempValue2("");
    setTempValue3("");
    setTempAction("");

    setTempActionValue("");
    setTempValue1_USER1("");
    setTempValue2_USER1("");
    setTempValue3_USER1("");
    setTempAction_USER1("");
    setTempActionValue_USER1("");

    setTempValue1_USER2("");
    setTempValue2_USER2("");
    setTempValue3_USER2("");
    setTempAction_USER2("");
    setTempActionValue_USER2("");

    setTempValue1_USER3("");
    setTempValue2_USER3("");
    setTempValue3_USER3("");
    setTempAction_USER3("");
    setTempActionValue_USER3("");

    setTempValue1_USER4("");
    setTempValue2_USER4("");
    setTempValue3_USER4("");
    setTempAction_USER4("");
    setTempActionValue_USER4("");

    setTempValue1_USER5("");
    setTempValue2_USER5("");
    setTempValue3_USER5("");
    setTempAction_USER5("");
    setTempActionValue_USER5("");

    setTempValue1_USER6("");
    setTempValue2_USER6("");
    setTempValue3_USER6("");
    setTempAction_USER6("");
    setTempActionValue_USER6("");
  };

  const handleSave = async () => {
    if (!selectedRow) return;
    const updatedData = {
      rule_name: selectedRow.rule_name,
      value_1: tempValue1,
      value2: tempValue2,
      value3: tempValue3,
      action: tempAction,
      action_value: tempActionValue,
      value1_USER1: tempValue1_USER1,
      value2_USER1: tempValue2_USER1,
      value3_USER1: tempValue3_USER1,
      action_USER1: tempAction_USER1,
      action_value_USER1: tempActionValue_USER1,
      value1_USER2: tempValue1_USER2,
      value2_USER2: tempValue2_USER2,
      value3_USER2: tempValue3_USER2,
      action_USER2: tempAction_USER2,
      action_value_USER2: tempActionValue_USER2,
      value1_USER3: tempValue1_USER3,
      value2_USER3: tempValue2_USER3,
      value3_USER3: tempValue3_USER3,
      action_USER3: tempAction_USER3,
      action_value_USER3: tempActionValue_USER3,
      value1_USER4: tempValue1_USER4,
      value2_USER4: tempValue2_USER4,
      value3_USER4: tempValue3_USER4,
      action_USER4: tempAction_USER4,
      action_value_USER4: tempActionValue_USER4,
      value1_USER5: tempValue1_USER5,
      value2_USER5: tempValue2_USER5,
      value3_USER5: tempValue3_USER5,
      action_USER5: tempAction_USER5,
      action_value_USER5: tempActionValue_USER5,
      value1_USER6: tempValue1_USER6,
      value2_USER6: tempValue2_USER6,
      value3_USER6: tempValue3_USER6,
      action_USER6: tempAction_USER6,
      action_value_USER6: tempActionValue_USER6,
      user_email: userEmail,
    };
    try {
      await axios.post(`${API_URL}/api/rules/update_rule_stop_loss_2/`, updatedData);
      await fetchData("roi", "rpc");
      toast.success("Data updated successfully!");
      handleEditDialogClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const userValue1 = {
    USER1: tempValue1_USER1,
    USER2: tempValue1_USER2,
    USER3: tempValue1_USER3,
    USER4: tempValue1_USER4,
    USER5: tempValue1_USER5,
    USER6: tempValue1_USER6,
  };

  const userValue2 = {
    USER1: tempValue2_USER1,
    USER2: tempValue2_USER2,
    USER3: tempValue2_USER3,
    USER4: tempValue2_USER4,
    USER5: tempValue2_USER5,
    USER6: tempValue2_USER6,
  };

  const userValue3 = {
    USER1: tempValue3_USER1,
    USER2: tempValue3_USER2,
    USER3: tempValue3_USER3,
    USER4: tempValue3_USER4,
    USER5: tempValue3_USER5,
    USER6: tempValue3_USER6,
  };

  const userAction = {
    USER1: tempAction_USER1,
    USER2: tempAction_USER2,
    USER3: tempAction_USER3,
    USER4: tempAction_USER4,
    USER5: tempAction_USER5,
    USER6: tempAction_USER6,
  };

  const userActionValue = {
    USER1: tempActionValue_USER1,
    USER2: tempActionValue_USER2,
    USER3: tempActionValue_USER3,
    USER4: tempActionValue_USER4,
    USER5: tempActionValue_USER5,
    USER6: tempActionValue_USER6,
  };

  const userFunctions = {
    USER1: {
      setUserValue1: setTempValue1_USER1,
      setUserValue2: setTempValue2_USER1,
      setUserValue3: setTempValue2_USER1,
      setUserAction: setTempAction_USER1,
      setUserActionValue: setTempActionValue_USER1,
    },
    USER2: {
      setUserValue1: setTempValue1_USER2,
      setUserValue2: setTempValue2_USER2,
      setUserValue3: setTempValue2_USER2,
      setUserAction: setTempAction_USER2,
      setUserActionValue: setTempActionValue_USER2,
    },
    USER3: {
      setUserValue1: setTempValue1_USER3,
      setUserValue2: setTempValue2_USER3,
      setUserValue3: setTempValue2_USER3,
      setUserAction: setTempAction_USER3,
      setUserActionValue: setTempActionValue_USER3,
    },
    USER4: {
      setUserValue1: setTempValue1_USER4,
      setUserValue2: setTempValue2_USER4,
      setUserValue3: setTempValue2_USER4,
      setUserAction: setTempAction_USER4,
      setUserActionValue: setTempActionValue_USER4,
    },
    USER5: {
      setUserValue1: setTempValue1_USER5,
      setUserValue2: setTempValue2_USER5,
      setUserValue3: setTempValue2_USER5,
      setUserAction: setTempAction_USER5,
      setUserActionValue: setTempActionValue_USER5,
    },
    USER6: {
      setUserValue1: setTempValue1_USER6,
      setUserValue2: setTempValue2_USER6,
      setUserValue3: setTempValue2_USER6,
      setUserAction: setTempAction_USER6,
      setUserActionValue: setTempActionValue_USER6,
    },
  };

  const handleValueChange = (field, value) => {
    if (userRole === "admin") {
      switch (field) {
        case "setValue1":
          setTempValue1(value);
          break;
        case "setValue2":
          setTempValue2(value);
          break;
        case "setValue3":
          setTempValue3(value);
          break;
        case "setAction":
          setTempAction(value);
          break;
        case "setActionValue":
          setTempActionValue(value);
          break;
        default:
          break;
      }
    } else {
      const userSetFunctions = userFunctions[userName];
      if (userSetFunctions) {
        switch (field) {
          case "setValue1":
            userSetFunctions.setUserValue1(value);
            break;
          case "setValue2":
            userSetFunctions.setUserValue2(value);
            break;
          case "setValue3":
            userSetFunctions.setUserValue2(value);
            break;
          case "setAction":
            userSetFunctions.setUserAction(value);
            break;
          case "setActionValue":
            userSetFunctions.setUserActionValue(value);
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">ROI check</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={firstRuleStatus1}
                  onChange={() => {
                    setFirstRuleStatus1(!firstRuleStatus1);
                    handleToggle(
                      "roi_check_1",
                      userRole === "admin"
                        ? "rule_global_status"
                        : `rule_global_status_${userName}`,
                      firstRuleStatus1
                    );
                  }}
                />
              }
              label={`stop_loss_2 (roi_check) - ${firstRuleStatus2 ? "Active" : "Paused"}`}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData1} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">RPC check</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={firstRuleStatus2}
                  onChange={() => {
                    setFirstRuleStatus2(!firstRuleStatus2);
                    handleToggle(
                      "rpc_check_1",
                      userRole === "admin"
                        ? "rule_global_status"
                        : `rule_global_status_${userName}`,
                      firstRuleStatus2
                    );
                  }}
                />
              }
              label={`stop_loss_2 (rpc_check) - ${firstRuleStatus2 ? "Active" : "Paused"}`}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData2} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <AccountCheckboxList
                ruleName="stop_loss_2"
                fetchApiEndpoint={`${API_URL}/api/rules/get_accounts_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <CampaignCheckboxList
                ruleName="stop_loss_2"
                fetchApiEndpoint={`${API_URL}/api/rules/get_campaigns_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableDataAdsetLogs} canSearch entriesPerPage={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/*Edit Dialog */}
      <DialogFormStopLoss2
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleEditDialogClose}
        handleSave={handleSave}
        selectedRow={selectedRow}
        userRole={userRole}
        userName={userName}
        handleValueChange={handleValueChange}
        actionOptions={actionOptions}
        userValue1={userValue1}
        userValue2={userValue2}
        userValue3={userValue3}
        userAction={userAction}
        userActionValue={userActionValue}
        tempValue1={tempValue1}
        tempValue2={tempValue2}
        tempValue3={tempValue3}
        tempAction={tempAction}
        tempActionValue={tempActionValue}
        handleActionChange={handleActionChange}
        selectedAction={selectedAction}
        Tips={renderTips(budgetROITips)}
      />
    </DashboardLayout>
  );
}

export default StopLoss2;
