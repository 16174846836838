import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import AccountCheckboxList from "../data_accounts";
import CampaignCheckboxList from "../data_campaigns";
import { Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import MDTypography from "../../../components/MDTypography";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useFilteredData, useTableDataAdsetLogs } from "../adsets_logs";
import DialogFormStopLoss1V2 from "./stop_loss_1_v2_dialog";
import TextField from "@mui/material/TextField";
import { getCurrentTimeDifference } from "../../../CUSTOM_COMPONENTS/time_functions";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";

function StopLoss1_v2() {
  const [tableData1, setTableData1] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "30%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "58%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "10%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" },
    ],
    rows: [],
  });

  const [tableData2, setTableData2] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "30%" },
      { Header: "condition", accessor: "condition", disableSortBy: true, width: "58%" },
      { Header: "action", accessor: "action", disableSortBy: true, width: "10%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "2%" },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [tempValue1, setTempValue1] = useState("");
  const [tempValue2, setTempValue2] = useState("");
  const [tempValue3, setTempValue3] = useState("");
  const [tempNotifyOnly, setTempNotifyOnly] = useState("");
  const [tempActionOnly, setTempActionOnly] = useState("");
  const [tempActionValue1, setTempActionValue1] = useState("");

  const [tempValue1_USER1, setTempValue1_USER1] = useState("");
  const [tempValue2_USER1, setTempValue2_USER1] = useState("");
  const [tempValue3_USER1, setTempValue3_USER1] = useState("");
  const [tempNotifyOnly_USER1, setTempNotifyOnly_USER1] = useState("");
  const [tempActionOnly_USER1, setTempActionOnly_USER1] = useState("");
  const [tempActionValue1_USER1, setTempActionValue1_USER1] = useState("");

  const [tempValue1_USER2, setTempValue1_USER2] = useState("");
  const [tempValue2_USER2, setTempValue2_USER2] = useState("");
  const [tempValue3_USER2, setTempValue3_USER2] = useState("");
  const [tempNotifyOnly_USER2, setTempNotifyOnly_USER2] = useState("");
  const [tempActionOnly_USER2, setTempActionOnly_USER2] = useState("");
  const [tempActionValue1_USER2, setTempActionValue1_USER2] = useState("");

  const [tempValue1_USER3, setTempValue1_USER3] = useState("");
  const [tempValue2_USER3, setTempValue2_USER3] = useState("");
  const [tempValue3_USER3, setTempValue3_USER3] = useState("");
  const [tempNotifyOnly_USER3, setTempNotifyOnly_USER3] = useState("");
  const [tempActionOnly_USER3, setTempActionOnly_USER3] = useState("");
  const [tempActionValue1_USER3, setTempActionValue1_USER3] = useState("");

  const [tempValue1_USER4, setTempValue1_USER4] = useState("");
  const [tempValue2_USER4, setTempValue2_USER4] = useState("");
  const [tempValue3_USER4, setTempValue3_USER4] = useState("");
  const [tempNotifyOnly_USER4, setTempNotifyOnly_USER4] = useState("");
  const [tempActionOnly_USER4, setTempActionOnly_USER4] = useState("");
  const [tempActionValue1_USER4, setTempActionValue1_USER4] = useState("");

  const [tempValue1_USER5, setTempValue1_USER5] = useState("");
  const [tempValue2_USER5, setTempValue2_USER5] = useState("");
  const [tempValue3_USER5, setTempValue3_USER5] = useState("");
  const [tempNotifyOnly_USER5, setTempNotifyOnly_USER5] = useState("");
  const [tempActionOnly_USER5, setTempActionOnly_USER5] = useState("");
  const [tempActionValue1_USER5, setTempActionValue1_USER5] = useState("");

  const [tempValue1_USER6, setTempValue1_USER6] = useState("");
  const [tempValue2_USER6, setTempValue2_USER6] = useState("");
  const [tempValue3_USER6, setTempValue3_USER6] = useState("");
  const [tempNotifyOnly_USER6, setTempNotifyOnly_USER6] = useState("");
  const [tempActionOnly_USER6, setTempActionOnly_USER6] = useState("");
  const [tempActionValue1_USER6, setTempActionValue1_USER6] = useState("");

  const [firstRuleStatus2, setFirstRuleStatus2] = useState(false);
  const [ruleActivationHour, setRuleActivationHour] = useState("");
  const [ruleActivationHourEst, setRuleActivationHourEst] = useState("");
  const [ruleActivationHourEst2, setRuleActivationHourEst2] = useState("");
  const [ruleInterval, setRuleInterval] = useState("");
  const [ruleActivateCampaignAdsetTime, setActivateCampaignAdsetTime] = useState("");

  const tableDataAdsetLogs = useTableDataAdsetLogs("stop_loss_1");
  const filteredDataLogs = useFilteredData(tableDataAdsetLogs, search);

  const API_URL = "https://dashboard-1049948018753.us-central1.run.app";

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const userNames = {
    "Artemyusi@gmail.com": "USER1",
    "Vlad0986613794@gmail.com": "USER2",
    "nuzhnyias@gmail.com": "USER3",
    "andreylipovskiy0@gmail.com": "USER4",
    "mcerneva2@gmail.com": "USER5",
    "Hembarskyi_user@gmail.com": "USER6",
  };

  const budgetROITips = [
    "The symbols < and > exclude the number they compare to, so the boundary is not included.",
    "The symbols <= and >= include the number they compare to, so the boundary is included.",
    "For negative values, specify the dash symbol without spaces(ex. -25).",
  ];
  const ruleTips = [
    "Set the first activation time in the 24-hour format (Estonia time).",
    "You can configure the interval between activations (in hours).",
    "You can specify the hour for campaign or adset activation in Estonia time.",
    `Estonia Activation Hour: ${ruleActivationHourEst}:20`,
  ];

  const ruleTips2 = [
    "The rule does not apply between 10 AM and 2 PM.",
    "To disable a rule, turn it off for all accounts or use the toggle.",
    "If you disable the accounts or campaigns listed below, this will apply to all rules in this set.",
    "When you edit a rule, you're only editing it for yourself; it won't affect other people's campaigns.",
    "Campaigns that include matches from the exclusion list will be automatically excluded (TEST_CAMPAIGN, Engagement campaign, COST_CAP, COSTCAP, BID_CAP, BIDCAP, GC-UTC-305, GC-UTC-304, - L0, (S1_), L1",
    "Specify the hour in 24-hour format for the rule to run.",
    "Adjust the interval to repeat the rule every 1 or 2 hours.",
    `Estonia Start Hour: ${ruleActivationHourEst2}:20`,
  ];

  const ruleTips3 = ["Rules sl_5 and sl_6 applies from 00 to 20:00 in proper time."];

  const userName = userNames[userEmail];

  const generateCondition = (rule, userRole, userName) => {
    if (userRole === "admin") {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            {rule.condition1}
            {rule.value1}
            {rule.condition1_1}
            {rule.value2}
            {rule.condition1_3}
            {rule.value_3}
          </div>
        </pre>
      );
    } else {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            {rule.condition1}
            {rule[`value1_${userName}`]}
            {rule.condition1_1}
            {rule[`value2_${userName}`]}
            {rule.condition1_3}
            {rule[`value3_${userName}`]}
          </div>
        </pre>
      );
    }
  };

  const generateAction = (rule, userRole, userName) => {
    if (userRole === "admin") {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
              alt="Red Arrow"
              style={{ width: "24px", height: "24px", marginRight: "20px" }}
            />
            {rule.action1}
            {rule.action_value1}
            {rule.action1_1}
            {rule.action_value1}
          </div>
        </pre>
      );
    } else {
      return (
        <pre>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
              alt="Red Arrow"
              style={{ width: "24px", height: "24px", marginRight: "20px" }}
            />
            {rule.action1}
            {rule[`action_value1_${userName}`]}
            {rule.action1_1}
            {rule[`action_value1_${userName}`]}
          </div>
        </pre>
      );
    }
  };

  const fetchData = async () => {
    try {
      const response1 = await axios.post(`${API_URL}/api/rules/rules_stop_loss_1_v2/`, {
        rule_names: ["sl_1", "sl_2", "sl_3", "sl_4"],
      });
      const response2 = await axios.post(`${API_URL}/api/rules/rules_stop_loss_1_v2/`, {
        rule_names: [
          "sl_5",
          "sl_6",
          "sl_7",
          "sl_8",
          "sl_9",
          "sl_10",
          "sl_11",
          "sl_12",
          "sl_13",
          "sl_14",
        ],
      });

      // Обработка данных первого запроса
      const rows1 = response1.data.map((rule) => {
        if (rule.rule_name === "sl_1") {
          const activationHour =
            userRole === "admin"
              ? rule.activation_campaign_adset_hour
              : rule[`activation_campaign_adset_hour_${userName}`];

          const hourInt = parseInt(activationHour, 10);
          if (!isNaN(hourInt)) {
            const timeDifference = getCurrentTimeDifference();
            setRuleActivationHourEst((hourInt + timeDifference) % 24);
          }
        }
        setRuleActivationHour(
          userRole === "admin"
            ? rule.first_activation_hour
            : rule[`first_activation_hour_${userName}`]
        );
        setRuleInterval(
          userRole === "admin" ? rule.set_run_interval : rule[`set_run_interval_${userName}`]
        );
        setActivateCampaignAdsetTime(
          userRole === "admin"
            ? rule.activation_campaign_adset_hour
            : rule[`activation_campaign_adset_hour_${userName}`]
        );

        const conditionContent = generateCondition(rule, userRole, userName);
        const actionContent = generateAction(rule, userRole, userName);

        return {
          global_rule_status: rule.global_rule_status,
          rule_name: (
            <div>
              {/* Глобальный статус (для админа) */}
              <FormControlLabel
                control={
                  <Switch
                    checked={rule.rule_status}
                    onChange={() => {
                      const currentStatus = rule.rule_status;
                      handleToggle(rule.rule_name, "rule_status", currentStatus);
                    }}
                    disabled={userRole !== "admin"}
                  />
                }
                label={`${rule.rule_name}`}
              />

              {userRole !== "admin" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={rule[`rule_status_${userName}`]}
                      onChange={() => {
                        const currentUserStatus = rule[`rule_status_${userName}`];
                        handleToggle(rule.rule_name, `rule_status_${userName}`, currentUserStatus);
                      }}
                    />
                  }
                  label={`${userNames[userEmail]}`}
                />
              )}
            </div>
          ),
          condition: conditionContent,
          action: actionContent,
          condition1_3: rule.condition1_3,
          action1: rule.action1,
          action_value1: rule.action_value1,
          action1_1: rule.action1_1,
          edit: (
            <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
              <EditIcon />
            </IconButton>
          ),
        };
      });

      const rows2 = response2.data.map((rule) => {
        if (rule.rule_name === "sl_5") {
          setFirstRuleStatus2(
            userRole === "admin" ? rule.global_rule_status : rule[`global_rule_status_${userName}`]
          );
          const activationHour2 =
            userRole === "admin"
              ? rule.first_activation_hour
              : rule[`first_activation_hour_${userName}`];

          const hourInt = parseInt(activationHour2, 10);
          if (!isNaN(hourInt)) {
            const timeDifference = getCurrentTimeDifference();
            setRuleActivationHourEst2((hourInt + timeDifference) % 24);
          }
        }
        const conditionContent = generateCondition(rule, userRole, userName);
        const actionContent = generateAction(rule, userRole, userName);

        return {
          rule_name: (
            <div>
              {/* Глобальный статус (для админа) */}
              <FormControlLabel
                control={
                  <Switch
                    checked={rule.rule_status}
                    onChange={() => {
                      const currentStatus = rule.rule_status;
                      handleToggle(rule.rule_name, "rule_status", currentStatus);
                    }}
                    disabled={userRole !== "admin"}
                  />
                }
                label={`${rule.rule_name}`}
              />

              {userRole !== "admin" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={rule[`rule_status_${userName}`]}
                      onChange={() => {
                        const currentUserStatus = rule[`rule_status_${userName}`];
                        handleToggle(rule.rule_name, `rule_status_${userName}`, currentUserStatus);
                      }}
                    />
                  }
                  label={`${userNames[userEmail]}`}
                />
              )}
            </div>
          ),
          condition: conditionContent,
          action: actionContent,
          condition1_3: rule.condition1_3,
          action1: rule.action1,
          action_value1: rule.action_value1,
          action1_1: rule.action1_1,
          edit: (
            <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
              <EditIcon />
            </IconButton>
          ),
        };
      });

      setTableData1((prevState) => ({ ...prevState, rows: rows1 }));
      setTableData2((prevState) => ({ ...prevState, rows: rows2 }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggle = useCallback(async (ruleName = "", columnName, currentStatus) => {
    const newStatus = currentStatus === true ? "Inactive" : "Active";
    console.log("Current status/new status:", currentStatus, newStatus);

    // Send request to update rule status
    const url = `${API_URL}/api/rules_stop_loss_1_v2/update_status/`;

    try {
      console.log("rule_name/columnName/newStatus:", ruleName, columnName, newStatus);
      await axios.post(url, {
        rule_name: ruleName,
        column_name: columnName,
        status: newStatus,
        user_email: userEmail,
      });
      await fetchData();
    } catch (error) {
      setTableData1((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_name === ruleName ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      setTableData2((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_name === ruleName ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      toast.error(`Failed to update rule status for ID ${ruleName}.`);
    }
  }, []);

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);

    setTempValue1(row.value1);
    setTempValue2(row.value2);
    setTempValue3(row.value_3);
    setTempNotifyOnly(row.notify_only);
    setTempActionOnly(row.action_only);
    setTempActionValue1(row.action_value1);

    setTempValue1_USER1(row.value1_USER1);
    setTempValue2_USER1(row.value2_USER1);
    setTempValue3_USER1(row.value3_USER1);
    setTempNotifyOnly_USER1(row.notify_only_USER1);
    setTempActionOnly_USER1(row.action_only_USER1);
    setTempActionValue1_USER1(row.action_value1_USER1);

    setTempValue1_USER2(row.value1_USER2);
    setTempValue2_USER2(row.value2_USER2);
    setTempValue3_USER2(row.value3_USER2);
    setTempNotifyOnly_USER2(row.notify_only_USER2);
    setTempActionOnly_USER2(row.action_only_USER2);
    setTempActionValue1_USER2(row.action_value1_USER2);

    setTempValue1_USER3(row.value1_USER3);
    setTempValue2_USER3(row.value2_USER3);
    setTempValue3_USER3(row.value3_USER3);
    setTempNotifyOnly_USER3(row.notify_only_USER3);
    setTempActionOnly_USER3(row.action_only_USER3);
    setTempActionValue1_USER3(row.action_value1_USER3);

    setTempValue1_USER4(row.value1_USER4);
    setTempValue2_USER4(row.value2_USER4);
    setTempValue3_USER4(row.value3_USER4);
    setTempNotifyOnly_USER4(row.notify_only_USER4);
    setTempActionOnly_USER4(row.action_only_USER4);
    setTempActionValue1_USER4(row.action_value1_USER4);

    setTempValue1_USER5(row.value1_USER5);
    setTempValue2_USER5(row.value2_USER5);
    setTempValue3_USER5(row.value3_USER5);
    setTempNotifyOnly_USER5(row.notify_only_USER5);
    setTempActionOnly_USER5(row.action_only_USER5);
    setTempActionValue1_USER5(row.action_value1_USER5);

    setTempValue1_USER6(row.value1_USER6);
    setTempValue2_USER6(row.value2_USER6);
    setTempValue3_USER6(row.value3_USER6);
    setTempNotifyOnly_USER6(row.notify_only_USER6);
    setTempActionOnly_USER6(row.action_only_USER6);
    setTempActionValue1_USER6(row.action_value1_USER6);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);

    setTempValue1("");
    setTempValue2("");
    setTempValue3("");
    setTempNotifyOnly("");
    setTempActionOnly("");
    setTempActionValue1("");

    setTempValue1_USER1("");
    setTempValue2_USER1("");
    setTempValue3_USER1("");
    setTempNotifyOnly_USER1("");
    setTempActionOnly_USER1("");
    setTempActionValue1_USER1("");

    setTempValue1_USER2("");
    setTempValue2_USER2("");
    setTempValue3_USER2("");
    setTempNotifyOnly_USER2("");
    setTempActionOnly_USER2("");
    setTempActionValue1_USER2("");

    setTempValue1_USER3("");
    setTempValue2_USER3("");
    setTempValue3_USER3("");
    setTempNotifyOnly_USER3("");
    setTempActionOnly_USER3("");
    setTempActionValue1_USER3("");

    setTempValue1_USER4("");
    setTempValue2_USER4("");
    setTempNotifyOnly_USER4("");
    setTempActionOnly_USER4("");
    setTempActionValue1_USER4("");

    setTempValue1_USER5("");
    setTempValue2_USER5("");
    setTempValue3_USER5("");
    setTempNotifyOnly_USER5("");
    setTempActionOnly_USER5("");
    setTempActionValue1_USER5("");

    setTempValue1_USER6("");
    setTempValue2_USER6("");
    setTempValue3_USER6("");
    setTempNotifyOnly_USER6("");
    setTempActionOnly_USER6("");
    setTempActionValue1_USER6("");
  };

  const handleSave = async () => {
    if (!selectedRow) return;
    const updatedData = {
      rule_name: selectedRow.rule_name,
      value1: tempValue1,
      value2: tempValue2,
      value3: tempValue3,
      action_value: tempActionValue1,
      action_only: tempActionOnly,
      notify_only: tempNotifyOnly,

      value1_USER1: tempValue1_USER1,
      value2_USER1: tempValue2_USER1,
      value3_USER1: tempValue3_USER1,
      action_value_USER1: tempActionValue1_USER1,
      action_only_USER1: tempActionOnly_USER1,
      notify_only_USER1: tempNotifyOnly_USER1,

      value1_USER2: tempValue1_USER2,
      value2_USER2: tempValue2_USER2,
      value3_USER2: tempValue3_USER2,
      action_value_USER2: tempActionValue1_USER2,
      action_only_USER2: tempActionOnly_USER2,
      notify_only_USER2: tempNotifyOnly_USER2,

      value1_USER3: tempValue1_USER3,
      value2_USER3: tempValue2_USER3,
      value3_USER3: tempValue3_USER3,
      action_value_USER3: tempActionValue1_USER3,
      action_only_USER3: tempActionOnly_USER3,
      notify_only_USER3: tempNotifyOnly_USER3,

      value1_USER4: tempValue1_USER4,
      value2_USER4: tempValue2_USER4,
      value3_USER4: tempValue3_USER4,
      action_value_USER4: tempActionValue1_USER4,
      action_only_USER4: tempActionOnly_USER4,
      notify_only_USER4: tempNotifyOnly_USER4,

      value1_USER5: tempValue1_USER5,
      value2_USER5: tempValue2_USER5,
      value3_USER5: tempValue3_USER5,
      action_value_USER5: tempActionValue1_USER5,
      action_only_USER5: tempActionOnly_USER5,
      notify_only_USER5: tempNotifyOnly_USER5,

      value1_USER6: tempValue1_USER6,
      value2_USER6: tempValue2_USER6,
      value3_USER6: tempValue3_USER6,
      action_value_USER6: tempActionValue1_USER6,
      action_only_USER6: tempActionOnly_USER6,
      notify_only_USER6: tempNotifyOnly_USER6,

      user_email: userEmail,
    };
    try {
      await axios.post(`${API_URL}/api/rules/update_rule_stop_loss_1_v2/`, updatedData);
      await fetchData();
      toast.success("Data updated successfully!");
      handleEditDialogClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const userValue1 = {
    USER1: tempValue1_USER1,
    USER2: tempValue1_USER2,
    USER3: tempValue1_USER3,
    USER4: tempValue1_USER4,
    USER5: tempValue1_USER5,
    USER6: tempValue1_USER6,
  };

  const userValue2 = {
    USER1: tempValue2_USER1,
    USER2: tempValue2_USER2,
    USER3: tempValue2_USER3,
    USER4: tempValue2_USER4,
    USER5: tempValue2_USER5,
    USER6: tempValue2_USER6,
  };

  const userValue3 = {
    USER1: tempValue3_USER1,
    USER2: tempValue3_USER2,
    USER3: tempValue3_USER3,
    USER4: tempValue3_USER4,
    USER5: tempValue3_USER5,
    USER6: tempValue3_USER6,
  };

  const userNotifyOnly = {
    USER1: tempNotifyOnly_USER1,
    USER2: tempNotifyOnly_USER2,
    USER3: tempNotifyOnly_USER3,
    USER4: tempNotifyOnly_USER4,
    USER5: tempNotifyOnly_USER5,
    USER6: tempNotifyOnly_USER6,
  };

  const userActionOnly = {
    USER1: tempActionOnly_USER1,
    USER2: tempActionOnly_USER2,
    USER3: tempActionOnly_USER3,
    USER4: tempActionOnly_USER4,
    USER5: tempActionOnly_USER5,
    USER6: tempActionOnly_USER6,
  };

  const userActionValue = {
    USER1: tempActionValue1_USER1,
    USER2: tempActionValue1_USER2,
    USER3: tempActionValue1_USER3,
    USER4: tempActionValue1_USER4,
    USER5: tempActionValue1_USER5,
    USER6: tempActionValue1_USER6,
  };

  const userFunctions = {
    USER1: {
      setUserValue1: setTempValue1_USER1,
      setUserValue2: setTempValue2_USER1,
      setUserValue3: setTempValue3_USER1,
      setUserNotifyOnly: setTempNotifyOnly_USER1,
      setUserActionValue: setTempActionValue1_USER1,
      setUserActionOnly: setTempActionOnly_USER1,
    },
    USER2: {
      setUserValue1: setTempValue1_USER2,
      setUserValue2: setTempValue2_USER2,
      setUserValue3: setTempValue3_USER2,
      setUserNotifyOnly: setTempNotifyOnly_USER2,
      setUserActionValue: setTempActionValue1_USER2,
      setUserActionOnly: setTempActionOnly_USER2,
    },
    USER3: {
      setUserValue1: setTempValue1_USER3,
      setUserValue2: setTempValue2_USER3,
      setUserValue3: setTempValue3_USER3,
      setUserNotifyOnly: setTempNotifyOnly_USER3,
      setUserActionValue: setTempActionValue1_USER3,
      setUserActionOnly: setTempActionOnly_USER3,
    },
    USER4: {
      setUserValue1: setTempValue1_USER4,
      setUserValue2: setTempValue2_USER4,
      setUserValue3: setTempValue3_USER4,
      setUserNotifyOnly: setTempNotifyOnly_USER4,
      setUserActionValue: setTempActionValue1_USER4,
      setUserActionOnly: setTempActionOnly_USER4,
    },
    USER5: {
      setUserValue1: setTempValue1_USER5,
      setUserValue2: setTempValue2_USER5,
      setUserValue3: setTempValue3_USER5,
      setUserNotifyOnly: setTempNotifyOnly_USER5,
      setUserActionValue: setTempActionValue1_USER5,
      setUserActionOnly: setTempActionOnly_USER5,
    },
    USER6: {
      setUserValue1: setTempValue1_USER6,
      setUserValue2: setTempValue2_USER6,
      setUserValue3: setTempValue3_USER6,
      setUserNotifyOnly: setTempNotifyOnly_USER6,
      setUserActionValue: setTempActionValue1_USER6,
      setUserActionOnly: setTempActionOnly_USER6,
    },
  };

  const handleValueChange = (field, value) => {
    if (userRole === "admin") {
      switch (field) {
        case "setValue1":
          setTempValue1(value);
          break;
        case "setValue2":
          setTempValue2(value);
          break;
        case "setValue3":
          setTempValue3(value);
          break;
        case "setNotifyOnly":
          setTempNotifyOnly(value);
          break;
        case "setActionOnly":
          setTempActionOnly(value);
          break;
        case "setActionValue":
          setTempActionValue1(value);
          break;
        default:
          break;
      }
    } else {
      const userSetFunctions = userFunctions[userName];
      if (userSetFunctions) {
        switch (field) {
          case "setValue1":
            userSetFunctions.setUserValue1(value);
            break;
          case "setValue2":
            userSetFunctions.setUserValue2(value);
            break;
          case "setValue3":
            userSetFunctions.setUserValue3(value);
            break;
          case "setNotifyOnly":
            userSetFunctions.setUserNotifyOnly(value);
            break;
          case "setActionOnly":
            userSetFunctions.setUserActionOnly(value);
            break;
          case "setAction":
            userSetFunctions.setUserAction(value);
            break;
          case "setActionValue":
            userSetFunctions.setUserActionValue(value);
            break;
          default:
            break;
        }
      }
    }
  };

  const handleSubmitForm = async () => {
    const data = {
      column_name_activation_hour:
        userRole === "admin" ? "first_activation_hour" : `first_activation_hour_${userName}`,
      first_activation_hour: ruleActivationHour,
      column_name_interval:
        userRole === "admin" ? "set_run_interval" : `set_run_interval_${userName}`,
      set_run_interval: ruleInterval,
      column_name_activation_campaign_adset_hour:
        userRole === "admin"
          ? "activation_campaign_adset_hour"
          : `activation_campaign_adset_hour_${userName}`,
      activation_campaign_adset_hour: ruleActivateCampaignAdsetTime,
    };

    try {
      const response = await axios.post(
        `${API_URL}/api/rules/update_interval_or_activation_top_loss_2/`,
        data
      );
      console.log("Response:", response.data);
      toast.success(`Success!`);
      await fetchData();
    } catch (error) {
      console.error("Error updating rule activation hour 1:", error);
      toast.error(`Failed to update hour`);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={firstRuleStatus2}
                  onChange={() => {
                    setFirstRuleStatus2(!firstRuleStatus2);
                    handleToggle(
                      "stop_loss_1",
                      userRole === "admin"
                        ? "global_rule_status"
                        : `global_rule_status_${userName}`,
                      firstRuleStatus2
                    );
                  }}
                />
              }
              label={`stop_loss_1 - ${firstRuleStatus2 ? "Active" : "Paused"}`}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Activation</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips)}
              </MDBox>
            </MDBox>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1} ml={2}>
              <span
                style={{
                  display: "list-item",
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                (Da/Tonic Los-Angeles time) Activate campaign / adset hour:&nbsp;&nbsp;
              </span>
              <TextField
                value={ruleActivateCampaignAdsetTime}
                onChange={(e) => setActivateCampaignAdsetTime(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "4%" }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                :20&nbsp;&nbsp;
              </span>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1}>
              <Button
                onClick={handleSubmitForm}
                startIcon={<SaveIcon />}
                style={{
                  marginLeft: "-20px",
                  color: "black",
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData1} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Activation / Deactivation</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips2)}
              </MDBox>
            </MDBox>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1} ml={2}>
              <span
                style={{
                  display: "list-item",
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                (Da/Tonic Los-Angeles time) First rule run starts at&nbsp;&nbsp;
              </span>
              <TextField
                value={ruleActivationHour}
                onChange={(e) => setRuleActivationHour(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "2%" }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                :00&nbsp;&nbsp;with a repeat interval of (1 or 2)&nbsp;&nbsp;
              </span>
              <TextField
                value={ruleInterval}
                onChange={(e) => setRuleInterval(e.target.value)}
                variant="standard"
                size="small"
                type="number"
                style={{ width: "2%" }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "200", // regular
                  lineHeight: 1,
                  marginRight: "8px",
                }}
              >
                &nbsp;&nbsp;hours.
              </span>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" padding={1}>
              <Button
                onClick={handleSubmitForm}
                startIcon={<SaveIcon />}
                style={{
                  marginLeft: "-20px",
                  color: "black",
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {renderTips(ruleTips3)}
            </MDBox>
          </MDBox>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData2} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <AccountCheckboxList
                ruleName="stop_loss_1"
                fetchApiEndpoint={`${API_URL}/api/rules/get_accounts_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <CampaignCheckboxList
                ruleName="stop_loss_1"
                fetchApiEndpoint={`${API_URL}/api/rules/get_campaigns_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableDataAdsetLogs} canSearch entriesPerPage={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/*Edit Dialog */}
      <DialogFormStopLoss1V2
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleEditDialogClose}
        handleSave={handleSave}
        selectedRow={selectedRow}
        userRole={userRole}
        userName={userName}
        handleValueChange={handleValueChange}
        userValue1={userValue1}
        userValue2={userValue2}
        userValue3={userValue3}
        userActionValue={userActionValue}
        userNotifyOnly={userNotifyOnly}
        tempValue1={tempValue1}
        tempValue2={tempValue2}
        tempValue3={tempValue3}
        tempActionValue={tempActionValue1}
        tempNotifyOnly={tempNotifyOnly}
        tempActionOnly={tempActionOnly}
        userActionOnly={userActionOnly}
        Tips={renderTips(budgetROITips)}
      />
    </DashboardLayout>
  );
}

export default StopLoss1_v2;
