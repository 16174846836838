import React from "react";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";

const HintComponent = () => (
  <Tooltip
    title={
      <MDBox
        p={2}
        sx={{
          backgroundColor: "rgb(255,255,255)", // Darker background for better readability
          borderRadius: 1,
          maxWidth: "300px", // Adjust width as needed
          whiteSpace: "pre-wrap", // Preserve line breaks
        }}
      >
        <MDTypography
          variant="body2"
          sx={{
            color: "white",
            textAlign: "left", // Align text to the left
            fontWeight: "bold", // Make the text bold
          }}
        >
          Instruction how to update percentage
        </MDTypography>
        <MDTypography
          variant="body2"
          sx={{
            color: "white",
            textAlign: "left", // Align text to the left
          }}
        >
          <br />
          <br />
          Step 1: Enter campaign ID and click &apos;Get KW info&lsquo; button to see all KW-lists
          and their IDs with percentage
          <br />
          <br />
          Step 2: Write all keyword list IDs with an underscore and percentage, separate by enter.
          <br />
          <br />
          Example:
          <br />
          111111_45%
          <br />
          222222_30%
          <br />
          333333_25%
          <br />
          <br />
          Step 3: Ensure that total percentage is equal to 100%
          <br />
          <br />
          Step 4: Click &apos;Update percentage&lsquo; button
        </MDTypography>
      </MDBox>
    }
    placement="bottom"
    arrow
    PopperProps={{
      sx: {
        width: "auto", // Adjust width to fit content
        maxWidth: "none", // Remove max-width restriction if needed
      },
    }}
  >
    <HelpOutlineIcon sx={{ fontSize: 24, cursor: "pointer" }} />
  </Tooltip>
);

export default HintComponent;
